import React, {useState} from 'react';
import './DropDown.css'

const DropDown = (props) => {
    const {options, ...rest} = props
    // const [selectedOption, setSelectedOption] = useState(currentOption || options[0]);

    // const handleSelectChange = (event) => {
    //     const selectedValue = event.target.value;
    //     setSelectedOption(selectedValue);
    //     onSelect(selectedValue);
    // };

    return (
        <div className="custom-select">
            <select id="dropdown" {...rest}>
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropDown;