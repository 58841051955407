import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {createOTP, deleteProspectiveCustomer, setCurrentCustomer} from "../../../app/reducers/prospectiveCustomerReducer";
import ReactTable from "../../../components/ReactTable/ReactTable";
import {toast} from "react-toastify";
import {copyTextToClipboard} from "../../../utils";

const ProspectiveCustomerTable = ({ prospectiveCustomers, features }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const showDetails = (customer) => {
        dispatch(setCurrentCustomer(customer._id))
        features.setShowDetailsBlock(true)
    }
    const handleOtp = (e, id) => {
        e.stopPropagation()
        dispatch(createOTP(id))
    }
    const handleDelete = (e, id) => {
        e.stopPropagation()
        dispatch(deleteProspectiveCustomer(id))
    }
    const handleCopyURL = (e, rowData) => {
        e.stopPropagation()
        const baseUrl = window.location.origin
        const urlToCopy = `${baseUrl}/register/${rowData.otp}/${rowData._id}`;
        // alert(
        //     `Some browsers do not allow copying, Please manually copy the URL until a permanent fix is provided
        //                 ${urlToCopy}`
        // )
        copyTextToClipboard(urlToCopy)
        // navigator.clipboard.writeText(urlToCopy)
            .then(() => {
                toast.success(`URL copied to clipboard: ${urlToCopy}`)
                console.log('URL copied to clipboard:', urlToCopy);
            })
            .catch((error) => {
                toast.error('Error copying to clipboard')
                alert(`There was an error copying the URL. Please manually copy the URL until a permanent fix is provided ${urlToCopy}`)
                console.error('Error copying to clipboard:', error);
            });
    };

    const columns = [
        {header:'Contact Name', accessorKey:'contactName'},
        {header:'Email', accessorKey:'emailAddress'},
        {header:'Phone', accessorKey:'contactNumber'},
        {header:'Stage', accessorKey:'stage'},
        {header:'Company Name   ', accessorKey:'companyName'},
        {
            header:'Actions',
            accessorKey:'actions',
            cell: ({ row }) => {
                return <div
                    style={{
                        display: "flex",
                        height: "2rem",
                        padding: "0 1rem",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "0.25rem",
                        alignSelf: "stretch"
                    }}
                    >
                    {row.original.companyId
                        ?   (row.original.userRegistered
                            ?   null
                            :   <span onClick={(e) => handleOtp(e, row.original._id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M12.9167 6.25008L15.8333 3.33341M17.5 1.66675L15.8333 3.33341L17.5 1.66675ZM9.49165 9.67508C9.92194 10.0996 10.264 10.6051 10.4981 11.1624C10.7322 11.7197 10.8538 12.3178 10.8558 12.9223C10.8579 13.5267 10.7403 14.1257 10.5099 14.6845C10.2795 15.2433 9.94087 15.7511 9.51344 16.1785C9.08601 16.606 8.57825 16.9446 8.0194 17.175C7.46055 17.4054 6.86165 17.523 6.25718 17.5209C5.65271 17.5189 5.0546 17.3973 4.49731 17.1632C3.94002 16.9291 3.43454 16.587 3.00998 16.1567C2.17509 15.2923 1.71312 14.1346 1.72356 12.9328C1.734 11.7311 2.21603 10.5815 3.06582 9.73175C3.9156 8.88196 5.06516 8.39994 6.2669 8.38949C7.46863 8.37905 8.62639 8.84102 9.49082 9.67592L9.49165 9.67508ZM9.49165 9.67508L12.9167 6.25008L9.49165 9.67508ZM12.9167 6.25008L15.4167 8.75008L18.3333 5.83341L15.8333 3.33341L12.9167 6.25008Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            )
                        :   <span
                            onClick={(e) => {
                                e.stopPropagation()
                                navigate('/createCompanyForm', {state: {...row.original}})
                            }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M13.3334 17.5V4.16667C13.3334 3.72464 13.1578 3.30072 12.8452 2.98816C12.5326 2.67559 12.1087 2.5 11.6667 2.5H8.33335C7.89133 2.5 7.4674 2.67559 7.15484 2.98816C6.84228 3.30072 6.66669 3.72464 6.66669 4.16667V17.5M3.33335 5.83333H16.6667C17.5872 5.83333 18.3334 6.57953 18.3334 7.5V15.8333C18.3334 16.7538 17.5872 17.5 16.6667 17.5H3.33335C2.41288 17.5 1.66669 16.7538 1.66669 15.8333V7.5C1.66669 6.57953 2.41288 5.83333 3.33335 5.83333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                    }
                    {row.original.otpValid &&
                        <span onClick={(e) => handleCopyURL(e,row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clip-path="url(#clip0_7308_5165)">
                                    <path d="M8.33332 10.8332C8.69119 11.3117 9.14778 11.7075 9.67211 11.994C10.1964 12.2805 10.7762 12.4508 11.3722 12.4935C11.9681 12.5362 12.5663 12.4502 13.1261 12.2414C13.6859 12.0326 14.1942 11.7058 14.6167 11.2832L17.1167 8.78322C17.8756 7.99738 18.2956 6.94487 18.2861 5.85238C18.2766 4.7599 17.8384 3.71485 17.0659 2.94231C16.2934 2.16978 15.2483 1.73157 14.1558 1.72208C13.0633 1.71259 12.0108 2.13256 11.225 2.89156L9.79165 4.31656M11.6666 9.16656C11.3088 8.68811 10.8522 8.29224 10.3279 8.00577C9.80353 7.71931 9.22373 7.54896 8.62777 7.50628C8.03182 7.4636 7.43366 7.54958 6.87386 7.7584C6.31406 7.96723 5.80572 8.294 5.38332 8.71656L2.88332 11.2166C2.12432 12.0024 1.70435 13.0549 1.71384 14.1474C1.72334 15.2399 2.16154 16.2849 2.93407 17.0575C3.70661 17.83 4.75166 18.2682 5.84414 18.2777C6.93663 18.2872 7.98914 17.8672 8.77498 17.1082L10.2 15.6832" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_7308_5165">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                    }
                    <span onClick={(e) => handleDelete(e, row.original._id)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M2.5 5.00008H4.16667M4.16667 5.00008H17.5M4.16667 5.00008V16.6667C4.16667 17.1088 4.34226 17.5327 4.65482 17.8453C4.96738 18.1578 5.39131 18.3334 5.83333 18.3334H14.1667C14.6087 18.3334 15.0326 18.1578 15.3452 17.8453C15.6577 17.5327 15.8333 17.1088 15.8333 16.6667V5.00008H4.16667ZM6.66667 5.00008V3.33341C6.66667 2.89139 6.84226 2.46746 7.15482 2.1549C7.46738 1.84234 7.89131 1.66675 8.33333 1.66675H11.6667C12.1087 1.66675 12.5326 1.84234 12.8452 2.1549C13.1577 2.46746 13.3333 2.89139 13.3333 3.33341V5.00008M8.33333 9.16675V14.1667M11.6667 9.16675V14.1667" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                </div>
            },
        },
    ]

    return (
        <>
            <ReactTable
                title={'Prospective Customer'}
                Data={prospectiveCustomers}
                columns={columns}
                onRowClick={showDetails}
                extraComponent={features.extraComponent?features.extraComponent:null}
            />
        </>
    );
};

export default ProspectiveCustomerTable;
