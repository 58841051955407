import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../contextProviders/authContext";

const RequireAuth = ({children, allowedRoles}) => {
    const auth = useAuth()
    const location = useLocation()
    if (allowedRoles) {
        const userRole = auth.user.role;
        if (!allowedRoles.includes(userRole)) {
            return <Navigate to={'/unauthorizedPage'} />
        }
    }
    if (!auth.isAuthenticated()){
        return <Navigate to={'/login'} state={{path:location.pathname}} />
    }
    return children
};

export default RequireAuth;

