// redux/slices/appSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    snackbarMessage: '',
};

const licenseCodeSlice = createSlice({
    name: 'licenseCode',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        setSnackbarMessage: (state, action) => {
            state.snackbarMessage = action.payload;
        },
        clearSnackbarMessage: (state, action) => {
            state.snackbarMessage = ''
        },
    },
});

export const {
    startLoading,
    stopLoading,
    setSnackbarMessage,
    clearSnackbarMessage,
} = licenseCodeSlice.actions;
export default licenseCodeSlice.reducer;
