import { startLoading, stopLoading, setSnackbarMessage } from './licenseCodeReducer';
import axios from "../axios";

const formatDataForTextFile = (jsonData) => {
    let lines = []
    lines.push(jsonData.company_name)
    jsonData.machines.map(machine=>{
        lines.push(`${machine.machineId}-${machine.user}-${machine.encodedModulesCode}`)
        machine.codes.map(entry=>{
            lines.push(`${entry.date} ${entry.code}`)
            return null
        })
        lines.push(';')
    })
    return lines.join('\n')
};

const fetchDataFromApi = async (companyId) => {
    try {
        const response = await axios.get(`/license/getLicenseCodes/${companyId}`);
        return response.data; // Assuming your API returns the text data
    } catch (error) {
        throw error;
    }
};
export const downloadFile = (companyId) => async (dispatch) => {
    try {
        dispatch(startLoading());
        const jsonData = await fetchDataFromApi(companyId)
        // console.log(jsonData)
        const formattedData = formatDataForTextFile(jsonData);
        // Create a Blob containing the formatted text data
        const blob = new Blob([formattedData], { type: 'text/plain' });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an <a> element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'HIQ-CODES.txt'; // Specify the desired file name

        // Trigger a click event on the <a> element
        a.click();

        // Release the URL object
        window.URL.revokeObjectURL(url);

        dispatch(stopLoading());
        dispatch(setSnackbarMessage('File downloaded successfully.'));
    } catch (error) {
        dispatch(stopLoading());
        dispatch(setSnackbarMessage('Error downloading file.'));
    }
};
