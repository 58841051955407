import React, {useState} from 'react';
import image from "../../../assets/image1.jpg";
import Input from "../../../stories/Input/Input";
import Button from '../../../stories/Button/Button'
import {toast} from "react-toastify";
import {useAuth} from "../../../app/contextProviders/authContext";
import {useNavigate, useParams} from "react-router-dom";

const ResetPassword = () => {
    const { otp, userId } = useParams();
    const navigate = useNavigate()
    const auth = useAuth()
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
    })

    const handleChange = event => {
        const {name, value} = event.target
        setFormData(prevState => ({...prevState, [name]:value}))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (formData.newPassword !== formData.confirmPassword)
            return toast.error("Both Passwords MUST Match")
        auth.resetPassword(otp, userId, formData.newPassword)
        navigate('/login')
        console.log({formData})
    }
    return (
        <div className={'loginPage'}>
            <div className={'leftSide'}>
                <div className={'loginContainer'}>
                    <form onSubmit={handleSubmit}>
                        <div className={'login'}>
                            <div className={'titleContainer'}>
                                <div className={'title'}>Reset Password</div>
                            </div>
                            <div className={'fieldContainer'}>
                                <div className={'label'}>New Password</div>
                                <Input
                                    name={'newPassword'}
                                    type={'password'}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                            <div className={'fieldContainer'}>
                                <div className={'label'}>Re-enter New Password</div>
                                <Input
                                    name={'confirmPassword'}
                                    type={'password'}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                            <Button
                                label={'Update Password'}
                                type="submit"
                                fill={'#7F56D9'}
                                color={'#FFFFFF'}
                            />

                        </div>
                    </form>
                </div>
            </div>
            <div className={'rightSide'}>
                <img alt={"image1"} src={image} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
            </div>
        </div>
    );
};

export default ResetPassword;
