import React, {useEffect, useState} from 'react';
import Text from '../../../stories/Text/Text'
import Card from '../../../stories/Card/Card'
import {useCustomer} from "../../../app/contextProviders/customerContext";
import FileDropzone from "../../../components/dropzone/FileDropZone";
import {readFileIntoArray, readMachineFile} from "./machine/Machine";
import {useLicense} from "../../../app/contextProviders/licenseContext";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../app/contextProviders/authContext";
import {useNavigate} from "react-router-dom";
import PageTitleContainer from "../../../stories/PageTitleContainer/PageTitleContainer";
import Divider from "../../../stories/Divider/Divider";
import CardHeader from "../../../stories/Card/CardHeader/CardHeader";
import CardContent from "../../../stories/Card/CardContent/CardContent";
import LicenseDataBlock from "./license/LicenseDataBlock";
import MachineTable from "./machine/MachineTable";
import Button from "../../../stories/Button/Button";
import FeatherIcon from 'feather-icons-react'
import {downloadFile} from "../../../app/reducers/licenseCodeThunks";
import MachineActions from "./machine/MachineActions/MachineActions";
import CustomerProfile from "./CustomerProfile/CustomerProfile";
import AddLicenses from "./license/AddLicense/AddLicenses";
import RemoveLicense from "./license/RemoveLicense/RemoveLicense";
import './NewDashboard.css'
import { CiEdit } from "react-icons/ci";
import {setCurrentCompany} from "../../../app/reducers/customerReducer";
import MachineDelete from "./machine/MachineDelete/MachineDelete";

const NewCompanyDashboard = () => {
    const customerContext = useCustomer()
    const licenseContext = useLicense()
    const dispatch = useDispatch()
    const auth = useAuth()
    const navigate = useNavigate()
    const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);

    const [showMachineDelete, setShowMachineDelete] = useState(false);
    const [showMachineActions, setShowMachineActions] = useState(false);
    const [showAddLicenses, setAddLicenses] = useState(false);
    const [showRemoveLicenses, setRemoveLicenses] = useState(false);

    const fileUploadError = useSelector(state=>state.customers.fileUploadError)

    const companies = customerContext.companies
    const company = companies[currentCompanyIndex]
    useEffect(()=>{
        if (company) dispatch(setCurrentCompany(company))
    }, [company])
    
    const handleMachineActions = () => setShowMachineActions(true);
    const closeMachineActionsModal = () => setShowMachineActions(false);

    const handleMachineDelete = () => setShowMachineDelete(true);
    const closeMachineDeleteModal = () => setShowMachineDelete(false);

    const handleAddLicenses = () => setAddLicenses(true);
    const closeAddLicensesModal = () => setAddLicenses(false);

    const handleRemoveLicenses = () => setRemoveLicenses(true);
    const closeRemoveLicensesModal = () => setRemoveLicenses(false);

    const onFileDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const lines = await readFileIntoArray(file);
            const isValidFile = lines[0].substring(0, 3) === 'VER'
            if (isValidFile){
                const machine = readMachineFile(lines, licenseContext.modules)
                customerContext.addMachineToCompany(company._id, machine)
            }
        }
    }
    const handleDownload = () => {
        console.log(company)
        dispatch(downloadFile(company._id)).then(()=>{})
    }
    return (
        <div className={'dashboard'}>
            <PageTitleContainer children={<Text type={'pageTitle'} text={company?company.companyProfile.companyName:""} />}/>
            <div style={{display:'flex',flexDirection:'row', gap:'1.5rem', padding:'1rem 2rem', flex:"1 0 0"}}>
                <Card>
                    <CardHeader>
                        <div style={{height:"1.75rem", flex:"1 0 0"}}>
                            <Text type={'cardTitle'} text={'Company Profile'}/>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: "0.75rem"}}>
                            <Button icon={<CiEdit/>} label={'Edit Profile'} onClick={() => navigate('/company/editProfile')}/>
                        </div>
                    </CardHeader>
                    <Divider/>
                    <CardContent>
                        <CustomerProfile company={company}/>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <Text type={'cardTitle'} text={'Licenses'}/>
                        <div className={'licenseButtons'}>
                            {((company && company.download) || auth.user.role === 'admin') &&
                                <Button
                                    onClick={() => handleDownload()}
                                    icon={<FeatherIcon icon={"download"}/>}
                                    label={'Download'}
                                />
                            }
                            {auth.user.role === 'admin' &&
                                <>
                                    <Button
                                        onClick={handleRemoveLicenses}
                                        icon={<FeatherIcon icon={"trash-2"}/>}
                                        label={'Delete'}
                                    />
                                    <Button
                                        style={{backgroundColor:'#7F56D9', color:'#ffffff'}}
                                        onClick={() => navigate('/license/manage')}
                                        icon={<FeatherIcon style={{backgroundColor:'#7F56D9', color:'#ffffff'}} icon={"edit-2"}/>}
                                        label={'Edit'}
                                    />
                                    <Button
                                        style={{backgroundColor:'#7F56D9', color:'#ffffff'}}
                                        onClick={handleAddLicenses}
                                        icon={<FeatherIcon style={{backgroundColor:'#7F56D9', color:'#ffffff'}} icon={"plus"}/>}
                                        label={'Add'}
                                    />
                                </>
                            }
                        </div>
                    </CardHeader>
                    <Divider/>
                    <CardContent>
                        <LicenseDataBlock company={company}/>
                    </CardContent>
                </Card>
            </div>
            {((company && company.active) || auth.user.role === 'admin') &&
                <div style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', padding: '1rem 0', paddingRight: "4rem"}}>
                    <FileDropzone onDrop={onFileDrop} acceptedFileTypes={['.txt']}/>
                </div>
            }
            {company && company.machines && company.machines.length > 0 &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: '0rem 2rem',
                    paddingRight:"2rem",
                    paddingBottom:"2rem",
                    borderRadius: "1.25rem",
                    border: "1px solid #EAECF0",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
                }}>
                    <MachineTable company={company} action={handleMachineActions} deleteAction={handleMachineDelete}/>
                </div>
            }
            {showAddLicenses && (
                <>
                    <div className="overlay active" onClick={closeAddLicensesModal}></div>
                    <AddLicenses company={company} onClose={closeAddLicensesModal} />
                </>
            )}
            {showRemoveLicenses && (
                <>
                    <div className="overlay active" onClick={closeRemoveLicensesModal}></div>
                    <RemoveLicense company={company} onClose={closeRemoveLicensesModal} />
                </>
            )}
            {showMachineActions && (
                <>
                    <div className="overlay active" onClick={closeMachineActionsModal}></div>
                    <MachineActions company={company} onClose={closeMachineActionsModal} />
                </>
            )}
            {showMachineDelete && (
                <>
                    <div className="overlay active" onClick={closeMachineDeleteModal}></div>
                    <MachineDelete company={company} onClose={closeMachineDeleteModal} />
                </>
            )}
        </div>
    )
};

export default NewCompanyDashboard;
