import {createContext, useContext, useEffect, useState} from "react";
import {resetAuth, setAuth} from '../reducers/authReducer'
import {useDispatch} from "react-redux";
import axios from "../axios";
import {setCustomer} from "../reducers/customerReducer";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {getCustomers} from "../reducers/adminReducer";

const AuthContext = createContext(null)

const setSession = (accessToken, refreshToken = null) => {
    if (accessToken) {
        localStorage.setItem('token', accessToken)
        if(refreshToken){
            localStorage.setItem('refreshToken', refreshToken)
        }
        axios.defaults.withCredentials = true
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        delete axios.defaults.headers.common.Authorization
    }
}

export const AuthProvider = ({children}) => {
    const dispatch = useDispatch()
    const [user, setUser] = useState(null);
    
    const login = (credentials) => {
        // console.log({credentials})
        return axios.post('/auth/login', credentials)
            .then((res)=>{
                setSession(res.data.token, res.data.refreshToken)
                setUser(res.data)
                // console.log("in auth login:", res.data)
                dispatch(setAuth(res.data))
                if (res.data.role === 'company') {
                    // console.log("auth.login:", "company user detected");
                    dispatch(setCustomer(res.data));
                }
                return null
            })
            .catch((err) => {
                return err.fullError || 'An error occurred'; // Return the error message
            })
    }
    const google = () => {
        window.open("http://localhost:8080/auth/google", "_self")
    }
    const registerUser = (credentials) => {
        console.log(credentials)
        const {username, email, password, otp, companyId} = credentials
        return axios
            .post(`/auth/register/${otp}/${companyId}`, { username, email, password })
            .then((res) => {
                setSession(res.data.token);
                setUser(res.data);
                dispatch(setAuth(res.data))
                dispatch(setCustomer(res.data))
            })
            .catch((err) => {
                console.log({err});
                return err.fullError || 'An error occurred'; // Return the error message
            });
    }
    const changePassword = async (formData) => {
        return axios.post('/auth/changePassword', formData)
            .then((res) => {
                toast.success('Password changed successfully')
            })
            .catch((err) => {
                toast.error(err.fullError|| 'Password change failed')
            })
    }
    const register = (credentials) => {
        console.log(credentials)
        axios.post('/auth/registerAdmin', credentials)
            .then((res)=>{
                console.log(res.data)
                setSession(res.data.token)
                dispatch(setAuth(res.data))
                setUser(res.data)
                return null
            })
            .catch((err) => {
                console.log(err)
                return err.fullError || 'An error occurred';
            })
    }
    const isAuthenticated = ()=>{
        return !!user
    }
    const logout = async () => {
        await axios.get('/auth/logout')
        // window.location.href = '/auth/logout'
        setUser(null)
        setSession()
        dispatch(resetAuth())
    }

    const setNewCompanyUser = async (data) => {
        try{
            console.log('calling api...')
            const response = await axios.post('/auth/setNewCompanyUser', data)
            console.log('response received')
            if (response){
                console.log(response.data)
                toast.success("User Details switched successfully")
                dispatch(getCustomers())
            }
        }catch(error){
            console.log(error)
            toast.error(error.message? error.message:error)
        }
    }
    const resetPassword = async (otp, userId, newPassword) => {
        const response = await axios.post(`/auth/resetPassword/${otp}/${userId}`, {newPassword})
        if (response){
            console.log(response.data)
            toast.success('Password successfully set')
            return null
        }else{
            toast.error('There was an error')
        }
    }
    
    return (
        <AuthContext.Provider value={{user, login, google, logout, register,
            changePassword, registerUser, isAuthenticated, resetPassword, setNewCompanyUser
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
