import React, {useState} from 'react';
import Button from '../../../stories/Button/Button'
import Divider from '../../../stories/Divider/Divider'

import './Staff.css'
import Input from "../../../stories/Input/Input";
import {useDispatch} from "react-redux";
import DropDown from "../../../stories/DropDown/DropDown";
import {registerStaff} from "../../../app/reducers/adminReducer";

const AddStaff = ({closeThisBlock}) => {
    const initialFormData = {
        username:'',
        email:'',
        role:'sales',
        password:'Password1',
    }
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(initialFormData);

    const handleResetFormData = () => setFormData(initialFormData)
    const handleClose = () => {
        handleResetFormData()
        closeThisBlock()
    }
    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        closeThisBlock()
        dispatch(registerStaff(formData))
    };

    const icons = {
        TitleIcon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M20 8V14M23 11H17M12.5 7C12.5 9.20914 10.7091 11 8.5 11C6.29086 11 4.5 9.20914 4.5 7C4.5 4.79086 6.29086 3 8.5 3C10.7091 3 12.5 4.79086 12.5 7Z" stroke="#7F56D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        CloseIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
    }
    return (
        <div className={'addStaff'}>
            <form onSubmit={handleSubmit} className={'asHeaderSection'}>
                <div className={'asHeaderSection'}>
                    <div className={'asTitleBlock'}>
                        <div className={'asTitleIconContainer'}>
                            <div className={'asTitleIcon'}>
                                {icons.TitleIcon}
                            </div>
                        </div>
                        <div className={'asTitleTextContainer'}>
                            <div className={'asTitleText'}>
                                Add New Staff
                            </div>
                        </div>
                        <div className={'asCloseButtonContainer'}>
                            <Button
                                label={'Close'}
                                icon={icons.CloseIcon}
                                trailing={true}
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                </div>
                <Divider/>
                <div className={'asContentSection'}>
                    <div className={'asContentSection1'}>
                        <div className={'asContentBlock'}>
                            <div className={'asContentRow'}>
                                <div className={'asInputFieldSection'}>
                                    <div className={'asInputFieldBlock'}>
                                        <div className={'asInputFieldLabel'}>
                                            User Name
                                        </div>
                                        <Input
                                            placeholder={'Enter User Name'}
                                            value={formData.username}
                                            name={'username'}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className={'asInputFieldSection'}>
                                    <div className={'asInputFieldBlock'}>
                                        <div className={'asInputFieldLabel'}>
                                            Email Address
                                        </div>
                                        <Input
                                            type={'email'}
                                            placeholder={'Enter Email Address'}
                                            value={formData.email}
                                            name={'email'}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className={'asInputFieldSection'}>
                                    <div className={'asInputFieldBlock'}>
                                        <div className={'asInputFieldLabel'}>
                                            Role
                                        </div>
                                        <DropDown
                                            placeholder={'Assign a Role'}
                                            options={['admin', 'sales']}
                                            value={formData.role}
                                            name={'role'}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'asFooterSection'}>
                    <Divider/>
                    <div className={'asFooterBlock'}>
                        <div className={''}>
                            <Button type={'submit'} label={'Create Staff'}/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddStaff;
