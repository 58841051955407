import React from 'react';
import './Button.css'

const Button = (props) => {
    const {icon, label, trailing=false, disabled=false, fill='', color='', ...rest} = props
    const customerColors = {}
    if (color !== '')customerColors['color'] = color
    if (fill !== '') customerColors['backgroundColor'] = fill
    return (
        <button
            type={'button'}
            className={`button secondary medium ${disabled===true?'disabled':""}`}
            style={customerColors}
            {...rest}
        >
            {icon
                ?   (trailing
                        ? <>{label} <span className={'buttonIcon'}><div className={'buttonAdornment'}>{icon}</div></span></>
                        : <><span className={'buttonIcon'}><div className={'buttonAdornment'}>{icon}</div></span> {label}</>
                    )
                : <>{label}</>
            }
        </button>
    );
};

export default Button;
