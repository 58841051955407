export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        console.log('using navigator.clipboard.writeText')
        return await navigator.clipboard.writeText(text);
    } else {
        console.log("using document.execCommand('copy')")
        return document.execCommand('copy', true, text);
    }
}

