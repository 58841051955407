import React, {useState} from 'react';
import {useAuth} from "../../app/contextProviders/authContext";
import './NewSidebar.css'
import AvatarImage from '../../assets/company.png'
import FeatherIcon from "feather-icons-react";
import {Link, useNavigate} from 'react-router-dom';
import { IoLockClosedOutline } from "react-icons/io5";
import Button from '../../stories/Button/Button'
import ChangePassword from "../auth/ChangePassword/ChangePassword";

const NewSidebar = () => {
    const [showChangePassword, setShowChangePassword] = useState(false)
    const auth = useAuth()
    const navigate = useNavigate()
    const user = auth.user
    const showChangePasswordModal = () => {
        setShowChangePassword(true)
    }
    const hideChangePasswordModal = () => {
        setShowChangePassword(false)
    }
    const handleLogout = () => {
        auth.logout()
        navigate('/login')
    };

    const SidebarMenuItem = ({ to, icon, label }) => {
        if (typeof to === 'function') {
            return (
                <Button
                    label={label}
                    onClick={to}
                    icon={icon}
                    style={{justifyContent:'left'}}
                />
            )
        } else {
            return (<Link to={to} className="menuItem"><span>{icon}</span><span>{label}</span></Link>);
        }
    };
    return (
        <>
            <div className={'sidebar'}>
                <div>
                    <div className={'sidebarElements'}>
                        <div className={'userBlock'}>
                            <img alt={'profileImage'} src={AvatarImage}/>
                            <div className={'userBlock username'}>{user?.username}</div>
                            <div className={'userBlock roleText'}>{user?.role === 'company' ? 'Company Account Manager' : 'H Q C S Admin'}</div>
                            <div className={'email'}>{auth.user?.email}</div>
                        </div>
                        {user && user?.role === 'company' &&
                            <>
                                <div className={'sidebarElements status'}>
                                    <span className={'statusLabel'}>Account Status</span>
                                    <span className={'statusValue'}>{user?.active ? 'Active' : 'Inactive'}</span>
                                </div>
                            </>
                        }
                    </div>
                    <div className={'sidebarElements'}>
                        {user && user?.role !== 'company' &&
                            <>
                                <SidebarMenuItem to={'/'} icon={<FeatherIcon icon={'airplay'}/>} label={'Dashboard'}/>
                                <SidebarMenuItem to={'/prospectiveCustomers'} icon={<FeatherIcon icon={'trending-up'}/>} label={'Prospective Customers'}/>
                                <SidebarMenuItem to={'/staff'} icon={<FeatherIcon icon={'trending-up'}/>} label={'Staff'}/>
                            </>
                        }
                    </div>
                </div>
                <div className={'sidebarBottom'}>
                    <SidebarMenuItem to={showChangePasswordModal} icon={<IoLockClosedOutline/>} label={'Change Password'}/>
                    <SidebarMenuItem to={handleLogout} icon={<FeatherIcon icon={'log-out'}/>} label={'Logout'}/>
                </div>
            </div>
            {showChangePassword && (
                <>
                    <div className="overlay active" onClick={hideChangePasswordModal}></div>
                    <ChangePassword closeModal={hideChangePasswordModal}/>
                </>
            )}
        </>
    );
};

export default NewSidebar;