import React, {useEffect} from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import {useAuth} from "../../app/contextProviders/authContext";
import NewSidebar from "../sidebar/NewSidebar";
// import './layout.css'

const StandardLayout = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    useEffect(()=>{
        if (!auth.isAuthenticated()) {
            navigate('/login')
        }
    }, [])
    return (
        <>
            {auth && auth.user &&
                <div className={'app'}>
                    <NewSidebar/>
                    {/*<div className={'main-content'}>*/}
                    <div style={{
                        marginLeft:'15rem',
                        backgroundColor:'#F9F5FF',
                        padding:'1.5rem',
                        flex:'1',
                    }}>
                        <Outlet/>
                    </div>
                </div>
            }
        </>
    );
};

export default StandardLayout;
