import React from 'react';
import ReactTable from "../../../components/ReactTable/ReactTable";
import {useSelector} from "react-redux";
import {selectCompanies} from "../../../app/reducers/adminReducer";

const CompanyList = () => {
    const companies = useSelector(selectCompanies);
    const currentDate = new Date();
    if (companies.length === 0) return <></>
    
    const expiryDays = ({licenseSets}) => {
        if (licenseSets && licenseSets.length) {
            const allLicenses = licenseSets.reduce((licenses, licenseSet) => [...licenses, ...licenseSet.licenses], []);
            let daysUntilExpirationArray = allLicenses.map((license) => {
                return Math.floor((new Date(license.expiryDate) - currentDate) / (1000 * 60 * 60 * 24))
            })
            daysUntilExpirationArray = daysUntilExpirationArray.filter(value => value >= 0)
            return Math.min(...daysUntilExpirationArray);
        }else{
            return null
        }
    }
    
    const columns = [
        {header: 'Company ID', accessorKey:'companyId'},
        {header: 'Company Name', accessorKey:'companyProfile.companyName'},
        {header: 'Owner', accessorKey:'user.username'},
        {header: 'Email', accessorKey:'email'},
        {header: 'Contact', accessorKey:'companyProfile.phone'},
        // {header: 'Status', accessorKey: 'active', cell: info => info.getValue() ? <div style={{color: 'green'}}>Active</div> : <div style={{color: 'red'}}>Inactive</div>},
        {header: 'Download', accessorKey:'download', cell:info => info.getValue()?<div style={{color: 'green'}}>Enabled</div> : <div style={{color: 'red'}}>Disabled</div>},
        {header: 'Expiry Days', accessorFn: (info) => expiryDays(info)},
    ]

    return (
        <>
            {companies && companies.length &&
                <ReactTable
                    title={'Companies'}
                    Data={companies}
                    columns={columns}
                    onRowClick={()=>{}}
                />
            }
        </>
    );
};

export default CompanyList;