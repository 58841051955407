import React from 'react';
import './CardHeader.css'
// import Text from '../../Text/Text'

const CardHeader = (props) => {

    return (
        <div className={'cardHeader'}>
            {props.children}
        </div>
    );
};

export default CardHeader;
