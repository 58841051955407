import React from 'react';
import './ForgotPassword.css'
import Button from '../../../stories/Button/Button'

const ForgotPassword = ({closeModal}) => {
    const icons = {
        'HelpCircle': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#7F56D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
    }
    return (
        <div className={'forgotPasswordModal'}>
            <div className={'fpContentSection'}>
                <div className={'fpIconBlock'}>
                    <div className={'fpIcon'}>
                        {icons.HelpCircle}
                    </div>
                </div>
                <div className={'fpTextBlock'}>
                    <div className={'fpTitleText'}>
                        Forgot Password ?
                    </div>
                    <div className={'fpDescription'}>
                        These are the simple steps you can follow to successfully reset your password.
                    </div>
                </div>
                <div className={'fpTextBlock'}>
                    <div className={'fpStepText'}>
                        Step 1
                    </div>
                    <div className={'fpDescription'}>
                        Please send an email to the Admin to reset the password
                    </div>
                </div>
                <div className={'fpTextBlock'}>
                    <div className={'fpStepText'}>
                        Step 2
                    </div>
                    <div className={'fpDescription'}>
                        Admin will share a link to reset your password.
                    </div>
                </div>
                <div className={'fpTextBlock'}>
                    <div className={'fpStepText'}>
                        Step 3
                    </div>
                    <div className={'fpDescription'}>
                        Click on the link to set a new password.
                    </div>
                </div>
            </div>
            <div className={'fpFooter'}>
                <div className={'fpFooterContent'}>
                    <div className={'fpFooterActions'}>
                        <Button label={'Okay'} onClick={closeModal}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;