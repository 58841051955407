import {createSlice} from "@reduxjs/toolkit";

const initialState = {_id:'', email:'', isAuthenticated: false}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action)=>{
            // console.log(action.payload)
            return {...state, ...action.payload, isAuthenticated: true}
        },
        resetAuth: (state, action) => {
            return {...initialState}
        },
    },
    extraReducers: ()=>{}
})
export const {setAuth, resetAuth} = authSlice.actions
export default authSlice.reducer
