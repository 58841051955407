import React, {useState} from 'react';
import './RegisterUser.css'

const RegisterUser = ({submitHandler}) => {
    const [formData, setFormData] = useState({username: '', email: '', password: '',});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({...prevData, [name]: value,}));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        submitHandler(formData)
    };

    return (
        <div className={'registerPage'}>
            <div className={'formSection'}>
                <div className={'formContainer'}>
                    <form onSubmit={handleSubmit}>
                        <div className={'register'}>
                            <div className={'titleContainer'}>
                                <div className={'title'}>Sign Up</div>
                                <div className={'description'}>Welcome to H Q C S! </div>
                                <div className={'description'}>Please register your credentials</div>
                            </div>

                            <div className={'fieldContainer'}>
                                <div className={'label'}>Full Name</div>
                                <input className={'inputField'} type="text" name="username" value={formData.username} onChange={handleChange} required/>
                            </div>
                            <div className={'fieldContainer'}>
                                <div className={'label'}>Email</div>
                                <input className={'inputField'} type="email" name="email" value={formData.email} onChange={handleChange} required/>
                            </div>
                            <div className={'fieldContainer'}>
                                <div className={'label'}>Set a Password</div>
                                <input className={'inputField'} type="password" name="password" value={formData.password} onChange={handleChange} required/>
                            </div>
                            <button className={'registerButton'} type="submit">Register</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterUser;
