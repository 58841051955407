import React, {useState, useEffect, useRef} from 'react';
import {useAuth} from "../../../app/contextProviders/authContext";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import image from '../../../assets/image1.jpg'
import {toast} from "react-toastify";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import Button from "../../../stories/Button/Button";
import {MdOutlineMail} from 'react-icons/md'
import { TfiUnlock } from "react-icons/tfi";
import InputRef from "../../../stories/Input/InputRef";
import './customerLogin.css'

const CustomerLogin = () => {
    const auth = useAuth()
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const navigate = useNavigate()
    const location = useLocation()
    const redirectPath = location.state?.path || '/dashboard'
    useEffect(()=>{
        if (isAuthenticated) {
            navigate(redirectPath)
        }
    }, [isAuthenticated])
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const hideForgotPassword = () => setShowForgotPassword(false)

    const emailRef = useRef()
    const passwordRef = useRef()

    const handleForgotPassword = () => {
        setShowForgotPassword(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let error = await auth.login({email:emailRef.current.value, password:passwordRef.current.value})
        if (error){
            toast.error(error)
        }
    };

    return (
        <>
            <div className={'loginPage'}>
                <div className={'leftSide'}>
                    <div className={'loginContainer'}>
                        <form onSubmit={handleSubmit}>
                            <div className={'login'}>
                                <div className={'titleContainer'}>
                                    <div className={'title'}>Login</div>
                                    <div className={'description'}>Welcome! Please enter your credentials</div>
                                </div>
                                <div className={'fieldContainer'}>
                                    <div className={'label'}>Email</div>
                                    <InputRef
                                        placeholder={'Enter your email address'}
                                        startAdornment={<MdOutlineMail/>}
                                        ref={emailRef}
                                        type={'email'}
                                        required
                                    />
                                </div>
                                <div className={'fieldContainer'}>
                                    <div className={'label'}>Password</div>
                                    <InputRef
                                        type={'password'}
                                        startAdornment={<TfiUnlock/>}
                                        ref={passwordRef}
                                        required
                                    />
                                </div>
                                <div className={'forgotPasswordContainer'}>
                                    <div className={'messageText'}>Remembered for 30 Days</div>
                                    <div className={'link'} onClick={handleForgotPassword}>Forgot Password</div>
                                </div>
                                <Button
                                    type="submit"
                                    label={'Sign In'}
                                    fill={'#7F56D9'}
                                    color={'#FFFFFF'}
                                />
                                {/*    Sign In*/}
                                {/*</Button>*/}
                            </div>
                        </form>
                    </div>
                </div>
                <div className={'rightSide'}>
                    <img alt={"image1"} src={image} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                </div>
            </div>
            {showForgotPassword && (
                <>
                    <div className="overlay active" onClick={hideForgotPassword}></div>
                    <ForgotPassword closeModal={hideForgotPassword}/>
                </>
            )}
        </>
    );
};

export default CustomerLogin;
