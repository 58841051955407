import React from 'react';
import './PageTitleContainer.css'

const PageTitleContainer = (props) => {
    return (
        <div className={'pageTitleContainer'}>
            {props.children}
        </div>
    );
};

export default PageTitleContainer;