import React, {useEffect, useMemo, useState} from 'react';
import {flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import Input from "../../stories/Input/Input";
import {CiSearch} from "react-icons/ci";
import Divider from "../../stories/Divider/Divider";
import Button from "../../stories/Button/Button";
import {HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight} from "react-icons/hi";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import './react-table.css'
import DropDown from "../../stories/DropDown/DropDown";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const ReactTable = ({title, Data, columns, onRowClick = () => {}, extraComponent, options = {}}) => {
    const data = useMemo(() => Data, [Data])
    const [sorting, setSorting] = useState([])
    const [filtering, setFiltering] = useState('')
    const [rowSelection, setRowSelection] = useState({})
    const checkboxColumn = {
        id: "select",
        header: ({table}) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
        ),
        cell: ({row}) => (
            <IndeterminateCheckbox
                {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                }}
            />
        ),
    }
    if (options.enableRowSelection) {
        columns.unshift(checkboxColumn)
    }
    const tableInstance = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting: sorting,
            globalFilter: filtering,
            rowSelection: rowSelection,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering,
        onRowSelectionChange: setRowSelection,
        enableRowSelection: options.enableRowSelection || false,
    })
    
    // const handleRowSelection = (selectedRows) => {
    //     const DynamicElement = React.cloneElement(options.onRowSelection.node, {
    //         ...options.onRowSelection.params,
    //         selectedRows
    //     })
    //     return DynamicElement
    // }

    return (
        <div className={'rtContainer'}>
            <div className={'rtTitleBlock'}>
                <div className={'rtTitle'}>{title}</div>
                <div className={'rtAdhocBlock'}>
                    <Input
                        placeholder={'Search'}
                        startAdornment={<CiSearch/>}
                        value={filtering}
                        onChange={(e) => setFiltering(e.target.value)}
                    />
                    {extraComponent}
                </div>
            </div>
            {options.enableRowSelection && tableInstance.getSelectedRowModel().flatRows.length > 0 &&
                options.onRowSelection(tableInstance.getSelectedRowModel().flatRows)
            }
            <div className={'rtTableContainer'}>
                <table className={'.react-table'}>
                    <thead>
                    {
                        tableInstance.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id} className={'.rtHeader'}>
                                {
                                    headerGroup.headers.map(header => (
                                        <th
                                            className={'rtHeaderCell'}
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {
                                                        {asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]
                                                    }
                                                </div>
                                            )}
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </thead>
                    <tbody>
                    {
                        tableInstance.getRowModel().rows?.map(row => (
                            <tr
                                className={'rtDataRow'}
                                key={row.id}
                                onClick={() => onRowClick(row.original)}
                            >
                                {
                                    row.getVisibleCells()?.map(cell => {
                                        return <td className={'rtDataCell'} key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    })
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                {data && data.length > 10 &&
                    <div className={'rtPagination'}>
                        <Divider/>
                        <div className={'rtPaginationButtons'}>
                            <div className={'rtButtonSet'}>
                                <Button
                                    label={'First'}
                                    icon={<HiOutlineChevronDoubleLeft/>}
                                    onClick={() => tableInstance.setPageIndex(0)}
                                />
                                {tableInstance.getCanPreviousPage() &&
                                    <Button
                                        label={'Previous'}
                                        icon={<FaChevronLeft/>}
                                        onClick={() => tableInstance.previousPage()}
                                    />
                                }
                            </div>
                            <div className={'rtButtonSet'}>
                                <DropDown
                                    value={tableInstance.getState().pagination.pageSize}
                                    onChange={e => {
                                        tableInstance.setPageSize(Number(e.target.value))
                                    }}
                                    options={[5, 10, 25, 50, 100]}
                                />
                            </div>
                            
                            <div className={'rtButtonSet'}>
                                <div className={'rtPaginationPageDetails'}>
                                    Page <span className={'rtPaginationPageDetailsValue'}>{tableInstance.getState().pagination.pageIndex + 1}</span> of <span className={'rtPaginationPageDetailsValue'}>{tableInstance.getPageCount()}</span>
                                    {/*Page <span className={'rtPaginationPageDetailsValue'}>{tableInstance.getState().pagination.pageIndex + 1}</span> of <span className={'rtPaginationPageDetailsValue'}>{tableInstance.getPageCount()}</span>*/}
                                </div>
                            </div>
                            
                            <div className={'rtButtonSet'}>
                                <div className={'rtPaginationPageDetails'}>
                                    Go to Page:
                                    <span>
                                        <Input
                                            style={{width: "5rem"}}
                                            // className={'rtInput'}
                                            type={'number'}
                                            defaultValue={tableInstance.getState().pagination.pageIndex + 1}
                                            value={tableInstance.getState().pagination.pageIndex + 1}
                                            onChange={e => {
                                                let page = e.target.value ? Number(e.target.value) - 1 : 0
                                                if (page >= tableInstance.getPageCount() - 1) page = tableInstance.getPageCount() - 1
                                                tableInstance.setPageIndex(page)
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                            
                            <div className={'rtButtonSet'}>
                                {tableInstance.getCanNextPage() &&
                                    <Button
                                        label={'Next'}
                                        icon={<FaChevronRight/>}
                                        onClick={() => tableInstance.nextPage()}
                                        // disabled={!tableInstance.getCanNextPage()}
                                    />
                                }
                                <Button
                                    label={'Last'}
                                    icon={<HiOutlineChevronDoubleRight/>}
                                    onClick={() => tableInstance.setPageIndex(tableInstance.getPageCount() - 1)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ReactTable;

// <div>
//     {tableInstance.getSelectedRowModel().flatRows.length}
// </div>
