import React from 'react';
import './AdminDashboard.css'

const DashboardCard = ({cardTitle, cardValue, cardAction}) => {
    return (
        <div className={'dashboardCard'} onClick={cardAction}>
            <div className={'cardTitleBlock'}>
                <div className={'cardTitle'}>{cardTitle}</div>
                <div className={'cardValue'}>{cardValue}</div>
            </div>
        </div>
    );
};

export default DashboardCard;