import React, {useState} from 'react';
import Button from "../../../../../stories/Button/Button";
import Input from "../../../../../stories/Input/Input";
import {useCustomer} from "../../../../../app/contextProviders/customerContext";

const UpdateExpiryDate = ({companyId, licenses}) => {
    const customerContext = useCustomer()
    const [newExpiryDate, setNewExpiryDate] = useState(getTomorrow())
    function getTomorrow() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow.toISOString().split('T')[0];
    }
    const handleDateChange = (e) => {
        setNewExpiryDate(e.target.value);
    };
    
    // Function to update the date
    const handleUpdate = () => {
        console.log({companyId})
        console.log('Updated Date:', newExpiryDate);
        console.log({licenses})
        customerContext.updateExpiryDate(companyId, newExpiryDate, licenses)
    };
    
    const handleReset = () => {
        console.log({companyId})
        console.log({licenses})
        customerContext.resetExpiryDate(companyId, licenses)
    };
    
    return (
        <div style={{display:'flex', alignItems:'flex-start', gap:'1.5rem', width:'100%'}}>
            <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap:'1.5rem', width:'100%'}}>
                <div style={{display:'flex'}}>
                    <Input type={'date'} value={newExpiryDate} onChange={handleDateChange} min={getTomorrow()}/>
                </div>
                <div style={{display:'flex',alignItems:'flex-start'}}>
                    <Button style={{display:'flex', textWrap:'nowrap'}} label={'Update Licenses'} onClick={handleUpdate}/>
                    <Button label={'Reset Licenses'} onClick={handleReset}/>
                </div>
            </div>
        </div>
    );
};

export default UpdateExpiryDate;