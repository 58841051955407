import React from 'react';
import {AuthProvider} from "./authContext";
import {LicenseProvider} from "./licenseContext";
import {CustomerProvider} from "./customerContext";

const AllProviders = ({children}) => {

    return (
        <AuthProvider>
            <LicenseProvider>
                <CustomerProvider>
                    {children}
                </CustomerProvider>
            </LicenseProvider>
        </AuthProvider>
    );
};

export default AllProviders;
