import React from 'react';
import './MachineDetails.css'
import Button from "../../../stories/Button/Button";
import {useCustomer} from "../../../app/contextProviders/customerContext";

const MachineDetails = ({machine, handleRecycle, closeBlock}) => {
    return (
        <div className={'machineDetails'} onClick={closeBlock}>
            <div>Do you want to recycle this machine? </div>
            <div>Owned by {machine.user}</div>
            <div>Machine ID {machine.machineId}</div>
            <Button
                label={'Confirm'}
                fill={'#7F56D9'}
                color={'#FFFFFF'}
                onClick={() => handleRecycle(machine)}
            />
        </div>
    );
};
// {machine.user}

export default MachineDetails;