import React from 'react';
import './LicenseGrid.css'
import Text from "../Text/Text";

const LicenseGrid = (props) => {
    const {data} = props
    return (
        <div className={'licenseDataBlock'}>
            <div className={'licenseRow'}>
                <div className={'licenseLabel'}><Text type={'moduleTitle'} text={'Module'} /></div>
                <div className={'licenseValueBlock'}>
                    <div className={'licenseValue'}><Text type={'moduleTitle'} text={'Purchased'}/></div>
                    <div className={'licenseValue'}><Text type={'moduleTitle'} text={'Used'}/></div>
                    <div className={'licenseValue'}><Text type={'moduleTitle'} text={'Available'}/></div>
                    <div className={'licenseValue'}><Text type={'moduleTitle'} text={'Expired'}/></div>
                </div>
            </div>
            <div>
                {data && data.map(record => (
                    <div key={record.code} className={'licenseRow'}>
                        <div className={'licenseLabel'}><Text type={'label'} text={record.name}/></div>
                        <div className={'licenseValue'}><Text type={'data'} text={record.code}/></div>
                        <div className={'licenseValueBlock'}>
                            <div className={'licenseValue'}><Text type={'data'} text={record.purchased}/></div>
                            <div className={'licenseValue'}><Text type={'data'} text={record.used}/></div>
                            <div className={'licenseValue'}><Text type={'data'} text={record.available}/></div>
                            <div className={'licenseValue'}><Text type={'data'} text={record.expired}/></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default LicenseGrid;