import React from 'react';
import './TextArea.css'

const TextArea = (props) => {
    const {label, ...rest} = props
    return (
        <div className={'taBlock'}>
            {label &&
                <div className={'taLabel'}>
                    Notes
                </div>
            }
            <div className={'taInputBlock'}>
                <div className={'taInput'}>
                    <textarea {...rest}>

                    </textarea>
                </div>
            </div>
        </div>
    );
};

export default TextArea;