import React, { useState } from 'react';
import {useDispatch} from "react-redux";
import {submitProspectiveCustomer} from "../../../app/reducers/prospectiveCustomerReducer";
import './AddProspectiveCustomer.css'
import Button from '../../../stories/Button/Button'
import Divider from "../../../stories/Divider/Divider";
import Input from '../../../stories/Input/Input'
import DividerVertical from "../../../stories/DividerVertical/DividerVertical";
import TextArea from "../../../stories/TextArea/TextArea";

const AddProspectiveCustomer = ({closeThisBlock}) => {
    const initialFormData = {
        companyName: '',
        contactName: '',
        contactNumber: '',
        emailAddress: '',
        content: '',
        attachments: [],
    }
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(initialFormData);

    const handleResetFormData = () => setFormData(initialFormData)
    const handleClose = () => {
        handleResetFormData()
        closeThisBlock()
    }
    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
        const newAttachments = Array.from(files);
        setFormData((prevData) => ({...prevData, attachments: newAttachments}));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Send formData to your API for saving
        console.log(formData);
        closeThisBlock()
        dispatch(submitProspectiveCustomer(formData))
    };
    const TitleIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M20 8V14M23 11H17M12.5 7C12.5 9.20914 10.7091 11 8.5 11C6.29086 11 4.5 9.20914 4.5 7C4.5 4.79086 6.29086 3 8.5 3C10.7091 3 12.5 4.79086 12.5 7Z" stroke="#7F56D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    const CloseIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    return (
        <div className={'addProspectiveCustomer'}>
            <div className={'apcHeaderSection'}>
                <div className={'apcTitleBlock'}>
                    <div className={'apcTitleIconContainer'}>
                        <div className={'apcTitleIcon'}>
                            {TitleIcon}
                        </div>
                    </div>
                    <div className={'apcTitleTextContainer'}>
                        <div className={'apcTitleText'}>
                            Add Prospective Customer
                        </div>
                    </div>
                    <div className={'apcCloseButtonContainer'}>
                        <Button
                            label={'Close'}
                            icon={CloseIcon}
                            trailing={true}
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </div>
            <Divider/>
            <div className={'apcContentSection'}>
                <div className={'apcContentSection1'}>
                    <div className={'apcContentBlock'}>
                        <div className={'apcContentRow'}>
                            <div className={'apcInputFieldSection'}>
                                <div className={'apcInputFieldBlock'}>
                                    <div className={'apcInputFieldLabel'}>
                                        Contact Name
                                    </div>
                                    <Input
                                        placeholder={'Enter Contact Name'}
                                        value={formData.contactName}
                                        name={'contactName'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className={'apcInputFieldSection'}>
                                <div className={'apcInputFieldBlock'}>
                                    <div className={'apcInputFieldLabel'}>
                                        Company Name
                                    </div>
                                    <Input
                                        placeholder={'Enter Company Name'}
                                        value={formData.companyName}
                                        name={'companyName'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'apcContentRow'}>
                            <div className={'apcInputFieldSection'}>
                                <div className={'apcInputFieldBlock'}>
                                    <div className={'apcInputFieldLabel'}>
                                        Email
                                    </div>
                                    <Input
                                        placeholder={'Enter Contact Email'}
                                        value={formData.emailAddress}
                                        name={'emailAddress'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className={'apcInputFieldSection'}>
                                <div className={'apcInputFieldBlock'}>
                                    <div className={'apcInputFieldLabel'}>
                                        Contact Phone
                                    </div>
                                    <Input
                                        placeholder={'Enter Contact Phone'}
                                        value={formData.contactNumber}
                                        name={'contactNumber'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DividerVertical/>
                <div className={'apcNotesSection'}>
                    <div className={'apcNotesContentBlock'}>
                        <div className={'apcNotesInputFieldSection'}>
                            <div className={'apcNotesInputFieldBlock'}>
                                <TextArea
                                    label={'Notes'}
                                    onChange={handleChange}
                                    name={'content'}
                                    placeholder={'Enter some initial notes'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'apcFooterSection'}>
                <Divider/>
                <div className={'apcFooterBlock'}>
                    <div className={''}>
                        <Button label={'Add Prospective Customer'} onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProspectiveCustomer;
