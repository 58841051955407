import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileDropzone = ({ onDrop, acceptedFileTypes }) => {
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: acceptedFileTypes.join(','), // Join accepted file types with commas
    });

    const containerStyles = {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        justifyContent: 'center',
        // alignSelf: 'stretch',
        border: '2px dashed #667085',
        borderRadius: '4px',
        textAlign: 'center',
        width: '100%',
        height: '8rem',
        margin: '0rem 2rem',
        cursor: 'pointer',
    };

    if (isDragActive) {
        containerStyles.borderColor = '#2196F3'; // Highlight when dragging
    }

    if (isDragAccept) {
        containerStyles.borderColor = '#00E676'; // Highlight when valid file is dragged
    }

    if (isDragReject) {
        containerStyles.borderColor = '#FF1744'; // Highlight when invalid file is dragged
    }

    const acceptedFileTypesMessage = acceptedFileTypes.join(', ');

    return (
        <div {...getRootProps()} style={containerStyles}>
            <input {...getInputProps()} />
            <p style={{
                fontFamily: 'Inter',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '1.25rem',
                color: '#6941C6'
            }}>
                {isDragActive
                    ? 'Drop the files here...'
                    : `Drag 'n' drop ${acceptedFileTypesMessage} files here, or click to select ${acceptedFileTypesMessage} files`}
            </p>
        </div>
    );
};

export default FileDropzone;
