import React from 'react';
import './CardContent.css'

const CardContent = (props) => {
    return (
        <div className={'cardContent'}>
            {props.children}
        </div>
    );
};

export default CardContent;