import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../axios";

export const getStaff = createAsyncThunk(
    'admin/getStaff',
    async () => {
        const response = await axios.get('/auth/staff');
        console.log('Staff: ', response.data)
        return response.data
    }
);

export const registerStaff = createAsyncThunk(
    'admin/registerStaff',
    async (data,{dispatch}) => {
        const response = await axios.post('/auth/registerStaff', data);
        console.log('Staff: ', response.data)
        dispatch(getStaff())
        return response.data
    }
);

export const getCustomers = createAsyncThunk(
    'admin/getCustomers',
    async () => {
        const response = await axios.get('/customer/list');
        return response.data
    }
);

export const getCustomer = createAsyncThunk(
    'admin/getCustomer',
    async (id) => {
        const response = await axios.get(`/customer/get/${id}`);
        console.log('getCustomer', response.data)
        return response.data
    }
);

export const activateCustomer = createAsyncThunk(
    'admin/activateCustomer',
    async (id,{dispatch}) => {
        const response = await axios.get(`/customer/activate/${id}`);
        dispatch(getCustomers())
        console.log('activateCustomer', response.data)
        return response.data
    }
);

export const deactivateCustomer = createAsyncThunk(
    'admin/deactivateCustomer',
    async (id,{dispatch}) => {
        const response = await axios.get(`/customer/activate/${id}`);
        dispatch(getCustomers())
        console.log('deactivateCustomer', response.data)
        return response.data
    }
);

export const getAllCompanies = createAsyncThunk(
    'admin/getAllCompanies',
    async () => {
        const response = await axios.get('/company/getCompanies');
        // console.log(response.data)
        return response.data
    }
);
export const getMachines = createAsyncThunk(
    'admin/getMachines',
    async () => {
        // console.log('calling /machine/getMachines')
        const response = await axios.get('/machine/getmachines');
        return response.data
    }
);

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        customers: [],
        staff:[],
        selectedCustomer: null,
        userCompanies: [],
        userCompanyCount: 0,
        companies: [],
        companyCount: 0,
        machines: [],
        machineCount: 0,
        searchType: null,
        filteredData: [], // This will hold the filtered data based on search
    },
    reducers: {
        setSearchData: (state, action) => {
            const {searchType, searchText} = action.payload;
            if (!searchText) {
                state.filteredData = [];
                return;
            }
            state.searchType = searchType
            switch (searchType){
                case 'email':
                    state.filteredData = state.customers.filter(
                        (customer) =>
                            customer.email.toLowerCase().includes(searchText.toLowerCase())
                    );
                    break;
                case 'company':
                    state.filteredData = state.companies.filter(
                        (company) =>
                            company.companyProfile.companyName.toLowerCase().includes(searchText.toLowerCase())
                    );
                    break;
                default:
                    break;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStaff.fulfilled, (state, action) => {
                state.staff = action.payload;
            })
            .addCase(registerStaff.fulfilled, (state, action) => {
                state.createdStaff = action.payload;
            })
            .addCase(getCustomers.fulfilled, (state, action) => {
                state.customers = action.payload;
            })
            .addCase(getCustomer.fulfilled, (state, action) => {
                state.selectedCustomer = action.payload;
            })
            .addCase(activateCustomer.fulfilled, (state, action) => {
                state.selectedCustomer = action.payload;
            })
            .addCase(deactivateCustomer.fulfilled, (state, action) => {
                state.selectedCustomer = action.payload;
            })
            .addCase(getAllCompanies.fulfilled, (state, action) => {
                state.companies = action.payload
            })
            .addCase(getMachines.fulfilled, (state, action) => {
                state.machines = action.payload
            })
    },
})

export const selectCustomers = (state) => state.admin.customers;
export const selectStaffList = (state) => state.admin.staff;
export const selectCustomer = (state) => state.admin.customer;
export const selectUsers = (state) => state.admin.userCompanies;
export const selectCompanies = (state) => state.admin.companies;
export const selectMachines = (state) => state.admin.machines;
export const selectMachineCount = (state) => state.admin.machineCount;
export const selectSearchType = (state) => state.admin.searchType;
export const selectFilteredDate = (state) => state.admin.filteredData;

export const { setSearchData } = adminSlice.actions;
export default adminSlice.reducer

