import React, {useEffect, useState} from 'react';
import { format } from 'date-fns';
import {listProspectiveCustomers, resetCurrentCustomer, selectCurrentCustomer, submitNote, updateProspectiveCustomer} from "../../../app/reducers/prospectiveCustomerReducer";
import {useDispatch, useSelector} from "react-redux";
import './ProspectiveCustomerDetails.css'
import Button from '../../../stories/Button/Button'
import Divider from "../../../stories/Divider/Divider";
import DividerVertical from "../../../stories/DividerVertical/DividerVertical";
import Input from "../../../stories/Input/Input";
import TextArea from "../../../stories/TextArea/TextArea";
import DropDown from "../../../stories/DropDown/DropDown";

const ProspectiveCustomerDetails = ({closeThisBlock}) => {
    const dispatch = useDispatch()
    const customer = useSelector(selectCurrentCustomer)
    const initialCustomerState = {
        _id: customer._id,
        companyName: customer.companyName,
        contactName: customer.contactName,
        contactNumber: customer.contactNumber,
        emailAddress: customer.emailAddress,
        status: customer.status,
        stage: customer.stage
    }
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [noteContent, setNoteContent] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [customerFormData, setCustomerFormData] = useState(initialCustomerState);
    useEffect(() => {
        setCustomerFormData({
            _id: customer._id,
            companyName: customer.companyName,
            contactName: customer.contactName,
            contactNumber: customer.contactNumber,
            emailAddress: customer.emailAddress,
            status: customer.status,
            stage: customer.stage
        });
    }, [customer]);
    useEffect(() => {
        setIsFormDirty(isFormDataChanged());
    }, [customerFormData, customer]);

    if (!customer) {
        return <div>Loading...</div>;
    }
    const isFormDataChanged = () => {
        return (
            customerFormData.companyName !== customer.companyName ||
            customerFormData.contactName !== customer.contactName ||
            customerFormData.contactNumber !== customer.contactNumber ||
            customerFormData.emailAddress !== customer.emailAddress ||
            customerFormData.status !== customer.status ||
            customerFormData.stage !== customer.stage
        );
    };
    const handleChange = (field, value) => {
        console.log({field, value})
        setCustomerFormData(prevFormData => ({
            ...prevFormData,
            [field]: value
        }));
    };
    const handleNoteContentChange = (e) => {
        setNoteContent(e.target.value);
    };
    const handleAttachmentChange = (e) => {
        const files = e.target.files;
        setAttachments(files);
    };
    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };
    const updateCustomer = () => {
        dispatch(updateProspectiveCustomer(customerFormData))
        setIsFormDirty(isFormDataChanged())
    }
    const addNote = () => {
        const formData = {
            content: noteContent,
            attachments: [],
            customerId: customer._id
        }

        for (const file of attachments) {
            formData.attachments.append(file);
        }
        dispatch(submitNote(formData))
        dispatch(listProspectiveCustomers())
        setNoteContent('')
    }
    const regenerateOTP = () => {
    }
    const handleClose = () => {
        dispatch(resetCurrentCustomer())
        closeThisBlock(false)
    }

    const icons = {
        'TitleIcon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z" stroke="#7F56D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        'CloseIcon': <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        'EditIcon': <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7393_6032)">
                <path d="M14.1666 2.49993C14.3855 2.28106 14.6453 2.10744 14.9313 1.98899C15.2173 1.87054 15.5238 1.80957 15.8333 1.80957C16.1428 1.80957 16.4493 1.87054 16.7353 1.98899C17.0213 2.10744 17.2811 2.28106 17.5 2.49993C17.7188 2.7188 17.8924 2.97863 18.0109 3.2646C18.1294 3.55057 18.1903 3.85706 18.1903 4.16659C18.1903 4.47612 18.1294 4.78262 18.0109 5.06859C17.8924 5.35455 17.7188 5.61439 17.5 5.83326L6.24996 17.0833L1.66663 18.3333L2.91663 13.7499L14.1666 2.49993Z" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_7393_6032">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>,
        'MessageIcon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z" stroke="#9E77ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        'AlertIcon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 8V12M12 16H12.01M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#9E77ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        'ViewIcon': <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7412_12237)">
                <path d="M1.3335 9.99992C1.3335 9.99992 4.66683 3.33325 10.5002 3.33325C16.3335 3.33325 19.6668 9.99992 19.6668 9.99992C19.6668 9.99992 16.3335 16.6666 10.5002 16.6666C4.66683 16.6666 1.3335 9.99992 1.3335 9.99992Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5002 12.4999C11.8809 12.4999 13.0002 11.3806 13.0002 9.99992C13.0002 8.61921 11.8809 7.49992 10.5002 7.49992C9.11945 7.49992 8.00016 8.61921 8.00016 9.99992C8.00016 11.3806 9.11945 12.4999 10.5002 12.4999Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_7412_12237">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>,

    }

    return (

        <div>
            <div className={'prospectiveCustomerDetails'}>
                <div className={'pcdHeaderSection'}>
                    <div className={'pcdTitleBlock'}>
                        <div className={'pcdTitleIconContainer'}>
                            <div className={'pcdTitleIcon'}>
                                {icons.TitleIcon}
                            </div>
                        </div>
                        <div className={'pcdTitleTextContainer'}>
                            <div className={'pcdTitleText'}>
                                Prospective Customer Details
                            </div>
                        </div>
                        <div className={'pcdCloseButtonContainer'}>
                            <Button
                                label={'Close'}
                                icon={icons.CloseIcon}
                                trailing={true}
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                </div>
                <Divider/>
                <div className={'pcdContentSection'}>
                    <div className={'pcdCustomerSection'}>
                        <div className={'pcdCustomerContent'}>
                            <div className={'pcdCustomerRow'}>
                                <div className={'pcdInputFieldSection'}>
                                    <div className={'pcdInputFieldBlock'}>
                                        <div className={'pcdInputFieldLabel'}>
                                            Contact Name
                                        </div>
                                        <Input
                                            name={'contactName'}
                                            value={customerFormData.contactName}
                                            onChange={(e) => handleChange('contactName', e.target.value)}
                                            disabled={!isEditMode}
                                        />
                                    </div>
                                    <div className={'pcdInputFieldBlock'}>
                                        <div className={'pcdInputFieldLabel'}>
                                            Company Name
                                        </div>
                                        <Input
                                            name={'companyName'}
                                            value={customerFormData.companyName}
                                            onChange={(e) => handleChange('companyName', e.target.value)}
                                            disabled={!isEditMode}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'pcdCustomerRow'}>
                                <div className={'pcdInputFieldSection'}>
                                    <div className={'pcdInputFieldBlock'}>
                                        <div className={'pcdInputFieldLabel'}>
                                            Email
                                        </div>
                                        <Input
                                            name={'emailAddress'}
                                            value={customerFormData.emailAddress}
                                            onChange={(e) => handleChange('emailAddress', e.target.value)}
                                            disabled={!isEditMode}
                                        />
                                    </div>
                                    <div className={'pcdInputFieldBlock'}>
                                        <div className={'pcdInputFieldLabel'}>
                                            Phone
                                        </div>
                                        <Input
                                            name={'contactNumber'}
                                            value={customerFormData.contactNumber}
                                            onChange={(e) => handleChange('contactNumber', e.target.value)}
                                            disabled={!isEditMode}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'pcdCustomerRow'}>
                                <div className={'pcdInputFieldSection'}>
                                    <div className={'pcdInputFieldBlock'}>
                                        <div className={'pcdInputFieldLabel'}>
                                            Status
                                        </div>
                                        <DropDown
                                            value={customerFormData.status}
                                            onChange={(e) => handleChange('status', e.target.value)}
                                            options={["Prospect","Customer"]}
                                            disabled={!isEditMode}
                                        />
                                    </div>
                                    <div className={'pcdInputFieldBlock'}>
                                        <div className={'pcdInputFieldLabel'}>
                                            Stage
                                        </div>
                                        <DropDown
                                            value={customerFormData.stage}
                                            onChange={(e) => handleChange('stage', e.target.value)}
                                            options={["Discuss","Demo","Trial","Live"]}
                                            disabled={!isEditMode}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'pcdCustomerFooter'}>
                            <Divider/>
                            <div className={'pcdCustomerFooterBlock'}>
                                <div className={'pcdButton'}>
                                    <Button
                                        label={isEditMode?(isFormDirty?'Save Changes':'View Mode'):'Edit Mode'}
                                        icon={isEditMode?(isFormDirty?null:icons.ViewIcon):icons.EditIcon}
                                        onClick={!isEditMode?toggleEditMode:(isFormDirty?updateCustomer:toggleEditMode)}
                                        fill={isFormDirty?'#7F56D9':null}
                                        color={isFormDirty?'#FFFFFF':null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <DividerVertical/>
                    <div className={'pcdNotesSection'}>
                        <div className={'pcdMessagesBlock'}>
                            <div className={'pcdMessages'}>
                                {customer && customer.notes && customer.notes.map((note) => (
                                    <div className={'pcdMessage'}>
                                        <div className={'pcdMessageIcon'}>
                                            {icons.MessageIcon}
                                        </div>
                                        <div className={'pcdMessageTextBlock'}>
                                            <div className={'pcdMessageTimestamp'}>
                                                {format(new Date(note.createdAt), 'MMM dd HH:mm')}
                                            </div>
                                            <div className={'pcdMessageText'}>
                                                {note.content}
                                            </div>
                                        </div>
                                        <Divider/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Divider/>
                        <div className={'pcdNoteSection'}>
                            <div className={'pcdNoteBlock'}>
                                <div className={'pcdTextarea'}>
                                    <TextArea onChange={handleNoteContentChange} value={noteContent}/>
                                </div>
                                <div className={'pcdNoteFooter'}>
                                    <div></div>
                                    <div className={'pcdButton'}>
                                        <Button
                                            label={'Add Note'}
                                            onClick={addNote}
                                            fill={noteContent?'#7F56D9':null}
                                            color={noteContent?'#FFFFFF':null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Paper elevation={1} sx={{padding: 2, marginBottom: 2}}>*/}
            {/*    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>*/}
            {/*        <Typography variant="h5" gutterBottom>*/}
            {/*            Prospective Customer Details*/}
            {/*            {!isEditMode && (*/}
            {/*                <Tooltip title="Edit">*/}
            {/*                    <IconButton onClick={toggleEditMode}>*/}
            {/*                        <EditIcon/>*/}
            {/*                    </IconButton>*/}
            {/*                </Tooltip>*/}
            {/*            )}*/}
            {/*        </Typography>*/}
            {/*        <Button label={'Close'} onClick={handleClose}>Close</Button>*/}
            {/*    </Box>*/}
            {/*    <Grid container spacing={2}>*/}
            {/*        <Grid item xs={6}>*/}
            {/*            <Paper elevation={3} sx={{padding: 2, height: '100%'}}>*/}
            {/*                <Grid container spacing={2}>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <TextField*/}
            {/*                            label={'Company Name'}*/}
            {/*                            fullWidth*/}
            {/*                            variant="standard"*/}
            {/*                            defaultValue={customerFormData.companyName}*/}
            {/*                            onChange={(e) => handleChange('companyName', e.target.value)}*/}
            {/*                            disabled={!isEditMode}*/}
            {/*                        />*/}
            {/*                        <TextField*/}
            {/*                            label={'Contact Name'}*/}
            {/*                            fullWidth*/}
            {/*                            variant="standard"*/}
            {/*                            defaultValue={customerFormData.contactName}*/}
            {/*                            onChange={(e) => handleChange('contactName', e.target.value)}*/}
            {/*                            disabled={!isEditMode}*/}
            {/*                        />*/}
            {/*                        <TextField*/}
            {/*                            label={'Contact Number'}*/}
            {/*                            fullWidth*/}
            {/*                            variant="standard"*/}
            {/*                            defaultValue={customerFormData.contactNumber}*/}
            {/*                            onChange={(e) => handleChange('contactNumber', e.target.value)}*/}
            {/*                            disabled={!isEditMode}*/}
            {/*                        />*/}
            {/*                        <TextField*/}
            {/*                            label={'Email Address'}*/}
            {/*                            fullWidth*/}
            {/*                            variant="standard"*/}
            {/*                            defaultValue={customerFormData.emailAddress}*/}
            {/*                            onChange={(e) => handleChange('emailAddress', e.target.value)}*/}
            {/*                            disabled={!isEditMode}*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <Typography>*/}
            {/*                            Status:{" "}*/}
            {/*                            <Select*/}
            {/*                                value={customerFormData.status}*/}
            {/*                                fullWidth*/}
            {/*                                variant="standard"*/}
            {/*                                onChange={(e) => handleChange('status', e.target.value)}*/}
            {/*                                disabled={!isEditMode}*/}
            {/*                            >*/}
            {/*                                <MenuItem value="Prospect">Prospect</MenuItem>*/}
            {/*                                <MenuItem value="Customer">Customer</MenuItem>*/}
            {/*                            </Select>*/}
            {/*                        </Typography>*/}
            {/*                        <Typography>*/}
            {/*                            Stage:{" "}*/}
            {/*                            <Select*/}
            {/*                                value={customerFormData.stage}*/}
            {/*                                fullWidth*/}
            {/*                                variant="standard"*/}
            {/*                                onChange={(e) => handleChange('stage', e.target.value)}*/}
            {/*                                disabled={!isEditMode}*/}
            {/*                            >*/}
            {/*                                <MenuItem value="Discuss">Discuss</MenuItem>*/}
            {/*                                <MenuItem value="Demo">Demo</MenuItem>*/}
            {/*                                <MenuItem value="Trial">Trial</MenuItem>*/}
            {/*                                <MenuItem value="Live">Live</MenuItem>*/}
            {/*                            </Select>*/}
            {/*                        </Typography>*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}

            {/*                {customer && customer.otp && (*/}
            {/*                    <>*/}
            {/*                        <Typography>OTP: {customer.otp}</Typography>*/}
            {/*                        <Button label={'Regenerate OTP'} onClick={regenerateOTP}>Regenerate OTP</Button>*/}
            {/*                    </>*/}
            {/*                )}*/}
            {/*                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
            {/*                    {isFormDirty && isEditMode &&*/}
            {/*                        <Button label={'Save'} variant="outlined" color="secondary" onClick={updateCustomer} sx={{margin: 2}}>Save</Button>*/}
            {/*                    }*/}
            {/*                    {!isFormDirty && isEditMode &&*/}
            {/*                        <Button label={'Go to View Mode'} variant="outlined" color="secondary" onClick={toggleEditMode} sx={{margin: 2}}>Go to View Mode</Button>*/}
            {/*                    }*/}
            {/*                </Box>*/}
            {/*            </Paper>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={6}>*/}
            {/*            <Paper elevation={3} sx={{padding: 2, height: '100%'}}>*/}
            {/*                <Typography variant="h6">Notes History</Typography>*/}
            {/*                <Box sx={{maxHeight: '300px', overflowY: 'scroll', border: '1px solid #ccc', padding: '8px', marginBottom: 2}}>*/}
            {/*                    <Grid container spacing={2}>*/}
            {/*                        {customer && customer.notes && customer.notes.map((note) => (*/}
            {/*                            <>*/}
            {/*                                <Grid item xs={2}>*/}
            {/*                                    <Typography>{format(new Date(note.createdAt), 'MMM dd HH:mm')}</Typography>*/}
            {/*                                </Grid>*/}
            {/*                                <Grid item xs={10}>*/}
            {/*                                    <Typography>{note.content}</Typography>*/}
            {/*                                </Grid>*/}
            {/*                                /!*<Typography variant="subtitle1" mt={2} gutterBottom>*!/*/}
            {/*                                /!*    Attachments:*!/*/}
            {/*                                /!*</Typography>*!/*/}
            {/*                                /!*<ul>*!/*/}
            {/*                                /!*    {note.attachments.map((attachment, index) => (*!/*/}
            {/*                                /!*        <li key={index}>{attachment}</li>*!/*/}
            {/*                                /!*    ))}*!/*/}
            {/*                                /!*</ul>*!/*/}
            {/*                            </>*/}
            {/*                        ))}*/}
            {/*                    </Grid>*/}
            {/*                </Box>*/}
            {/*                <Box>*/}
            {/*                    <TextField*/}
            {/*                        label="Note's Content"*/}
            {/*                        multiline*/}
            {/*                        fullWidth*/}
            {/*                        rows={4}*/}
            {/*                        value={noteContent}*/}
            {/*                        onChange={handleNoteContentChange}*/}
            {/*                    />*/}
            {/*                    <Button label={'Add Note'} variant="outlined" color="secondary" onClick={addNote} sx={{marginTop: 2}}>*/}
            {/*                        Add Note*/}
            {/*                    </Button>*/}
            {/*                </Box>*/}
            {/*            </Paper>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Paper>*/}
        </div>
    );
};

export default ProspectiveCustomerDetails;
