import React, {useEffect, useState} from 'react';
import './Staff.css'
import {useDispatch, useSelector} from "react-redux";
import {getStaff, selectStaffList} from "../../../app/reducers/adminReducer";
import ReactTable from "../../../components/ReactTable/ReactTable";
import Button from '../../../stories/Button/Button'
import AddStaff from "./AddStaff";

const Staff = () => {
    const dispatch = useDispatch()
    const staff = useSelector(selectStaffList)
    useEffect(() => {
        dispatch(getStaff())
    }, [])
    const [showAddStaff, setShowAddStaff] = useState(false)
    const hideAddStaff = () => setShowAddStaff(false)
    const handleToggleAddStaff = () => setShowAddStaff(!showAddStaff); // Use a callback function
    const columns = [
        {header:'User Name', accessorKey:'username'},
        {header:'Email', accessorKey:'email'},
        {header:'Status', accessorKey:'active'},
    ]
    const icons = {
        Plus: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.99984 4.16675V15.8334M4.1665 10.0001H15.8332" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        Minus: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M4.16675 10H15.8334" stroke="#6941C6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
    }

    const addStaffButton = <Button
        label={showAddStaff ? 'Hide Add Staff' : 'Add Staff'}
        onClick={handleToggleAddStaff}
        icon={showAddStaff? icons.Minus: icons.Plus}
        fill={showAddStaff ? '#FFFFFF' : '#7F56D9'}
        color={showAddStaff ? '#7F56D9' : '#FFFFFF'}

    />

    return (
        <>
            {showAddStaff && <AddStaff closeThisBlock={hideAddStaff}/>}
            {staff &&
                <ReactTable
                    title={'Staff List'}
                    Data={staff}
                    columns={columns}
                    extraComponent={addStaffButton}
                    // onRowClick={handleSelectUser}
                />
            }
        </>
    )
};

export default Staff;
