import React, {useState} from 'react';
import Input from "../../../stories/Input/Input";
import Button from "../../../stories/Button/Button";
import './UpdateUser.css'
import Divider from "../../../stories/Divider/Divider";
import {FaUserEdit} from "react-icons/fa";

const UpdateUser = ({userRecord, onSubmit, close}) => {
    const [user, setUser] = useState(userRecord || {})
    const handleChange = (e) => {
        const {name, value} = e.target
        setUser(prevState => ({...prevState, [name]: value}))
    }
    const handleSubmit = () => {
        onSubmit(user)
        close()
    }
    return (
        <div className={'updateUser'}>
            <div className={'uuFormContainer'}>
                <div className={'uuTitleContainer'}>
                    <div className={'uuTitleBlock'}>
                        <span><FaUserEdit/></span>
                        <div className={'uuTitle'}>Update User</div>
                    </div>
                    <div><Button label={'Close'} onClick={close}/></div>
                </div>
                <Divider/>
                <div className={'uuInputBlock'}>
                    <div className={'uuInputLabel'}>User Name</div>
                    <Input
                        type={'text'}
                        name={'username'}
                        value={user.username}
                        onChange={handleChange}
                        placeholder={'User Name'}
                        required
                    />
                </div>
                <div className={'uuInputBlock'}>
                    <div className={'uuInputLabel'}>Email</div>
                    <Input
                        type={'email'}
                        name={'email'}
                        value={user.email}
                        onChange={handleChange}
                        placeholder={'User Email'}
                        required
                    />
                </div>
                <div className={'uuNote'}>
                    Note: Changing the email will need the user to get his password reset
                </div>
                <div className={'uuInputBlock'}>
                    <Button onClick={handleSubmit} label={'Confirm Update'} fill={'#f97066'}/>
                </div>
            </div>
        </div>
    );
};

export default UpdateUser;