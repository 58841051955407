import {createContext, useContext, useState} from "react";
import axios from "../axios";

const LicenseContext = createContext(null)

export const LicenseProvider = ({children}) => {
    const [modules, setModules] = useState([])
    const loadModules = async () => {
        try{
            // console.log("Loading all Modules data")
            const response = await axios.get('/module/list')
            const data = response.data
            setModules(data)
        }catch(error){
            console.log(error)
        }
    }

    // console.log(`License module count: ${modules.length}`)
    return (
        <LicenseContext.Provider value={{modules, loadModules}}>
            {children}
        </LicenseContext.Provider>
    )
}

export const useLicense = () => {
    return useContext(LicenseContext)
}