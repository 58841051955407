import {BrowserRouter, Route, Routes} from "react-router-dom";
import StandardLayout from "./features/standardLayout/StandardLayout";
import CreateCompanyForm from "./features/admin/prospectiveCustomer/CreateCompanyForm";
import CustomerLogin from "./features/auth/Login/customerLogin";
import RequireAuth from "./app/auth/RequireAuth";
import Dashboard from "./features/dashboard";
import ProspectiveCustomers from "./features/admin/prospectiveCustomer/ProspectiveCustomers";
import NewCompanyDashboard from "./features/company/dashboard/newDashboard";
import RegisterAccount from "./features/auth/RegisterUser/RegisterAccount";
import EditProfile from "./features/company/dashboard/CustomerProfile/EditProfile/EditProfile";
import ResetPassword from "./features/auth/ResetPassword/ResetPassword";
import Staff from "./features/admin/Staff/Staff";
import UnauthorizedPage from "./UnauthorisedPage";
import PageNotFound from "./PageNotFound";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ManageLicense from "./features/company/dashboard/license/manageLicense/ManageLicense";

function App() {
    const toastConfig = {
        // Your configuration options go here
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    };
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path={'/register/:otp/:companyId'} element={<RegisterAccount/>}/>
                    <Route path={'/login'} element={<CustomerLogin/>}/>
                    <Route path={'/reset/:otp/:userId'} element={<ResetPassword/>}/>
                    <Route path="/unauthorizedPage" element={<UnauthorizedPage />} />

                    <Route path="/" element={<RequireAuth><StandardLayout/></RequireAuth>}>
                        <Route index element={<Dashboard/>}/>
                        <Route path={"/dashboard"} element={<Dashboard/>}/>
                        <Route path={"/prospectiveCustomers"} element={<RequireAuth allowedRoles={['admin','sales']}><ProspectiveCustomers/></RequireAuth>}/>
                        <Route path={"/staff"} element={<RequireAuth allowedRoles={['admin']}><Staff/></RequireAuth>}/>
                        <Route path={"/company/editProfile"} element={<RequireAuth allowedRoles={['company','admin']}><EditProfile/></RequireAuth>}/>
                        <Route path={"/company/dashboard"} element={<RequireAuth allowedRoles={['company','admin']}><NewCompanyDashboard/></RequireAuth>}/>
                        <Route path={"/createCompanyForm"} element={<RequireAuth allowedRoles={['admin']}><CreateCompanyForm/></RequireAuth>}/>
                        <Route path={"/license/manage"} element={<RequireAuth allowedRoles={['admin']}><ManageLicense/></RequireAuth>}/>
                    </Route>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
            <ToastContainer {...toastConfig} />
        </>
    );
}

export default App;


// deleted routes
// <Route path={"/machine/actions"} element={<MachineActions/>}/>
// <Route path={"/admin/userList"} element={<UserList/>}/>
// <Route path={"/admin/companyList"} element={<CompanyList/>}/>
// <Route path={"/admin/machineList"} element={<MachineList/>}/>
// <Route path={"/admin/customer"} element={<Customer/>}/>
// <Route path={"/admin/customer/:companyId"} element={<Customer/>}/>
// <Route path={'/registerAdmin'} element={<RegisterAdmin/>}/>
// <Route path={"/company-creation"} element={<CreateCompanyForm/>}/>
// <Route path="/" element={<StandardLayout/>}>
// <Route index element={<WelcomePage/>} />
// <Route path={"/verification"} element={<VerificationCode/>}/>
// <Route path={"/test"} element={<RegisterAccount/>}/>
