import React from 'react';
import "./Input.css";

const Input = (props) => {
    const { placeholder = "", startAdornment, endAdornment, ...rest } = props;

    // Check if startAdornment is provided
    const hasStartAdornment = startAdornment !== undefined;
    const hasEndAdornment = endAdornment !== undefined;

    return (
        <div className={`input-container${hasStartAdornment ? ' with-start-adornment' : ''}${hasEndAdornment ? ' with-end-adornment' : ''}`}>
            {hasStartAdornment && (
                <div className="start-icon-container">
                    {startAdornment}
                </div>
            )}
            <input
                className={`custom-input${hasStartAdornment ? ' with-start-adornment' : ''}${hasEndAdornment ? ' with-end-adornment' : ''}`}
                placeholder={placeholder}
                {...rest}
            />
            {hasEndAdornment && (
                <div className="end-icon-container">
                    {endAdornment}
                </div>
            )}
        </div>
    );
};

export default Input;
