import React, {useState} from 'react';
import './AddLicenses.css'
import { LuKey } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import Divider from '../../../../../stories/Divider/Divider'
import Input from '../../../../../stories/Input/Input'
import {useCustomer} from "../../../../../app/contextProviders/customerContext";

const AddLicenses = ({company, onClose}) => {
    const customerContext = useCustomer()
    const initialFormData = {
        companyId: company._id,
        licenseData: {
            'DR': 0,
            'CE': 0,
            'MU': 0,
            'DI': 0,
            'QU': 0,
            'SC': 0,
            'I': 0
        },
        "expiryDays": 1095
    }
    const [formData, setFormData] = useState(initialFormData)
    const resetFormData = () => {
        setFormData(initialFormData)
    }
    const handleDaysChange = (e) => {
        setFormData(prevState => ({...prevState, expiryDays: e.target.value}))
    }
    const handleLicenseChange = (code, amount) => {
        setFormData(prevState => ({
            ...prevState,
                licenseData: {
                    ...prevState.licenseData,
                    [code]:prevState.licenseData[code] + amount
                }
        }
        ))
    }
    // console.log(formData)
    const handleSubmit = () => {
        console.log({formData});
        customerContext.submitLicenseSet(formData)
        onClose()
        resetFormData()
    }

    const LicenseModule = (props) => {
        const {name, code} = props
        return (
            <div className={'adLicenseRow'}>
                <div className={'adName'}>{name}</div>
                <div className={'adDataBlock'}>
                    <div className={'code'}>{code}</div>
                    <div className={'adLicenseCountContainer'}>
                        <div className={'reduce'} onClick={() => {
                            if (formData.licenseData[code] > 0) handleLicenseChange(code, -1)
                        }}>
                            <FiMinus/>
                        </div>
                        <div className={'count'}>{formData.licenseData[code]}</div>
                        <div className={'increase'} onClick={() => handleLicenseChange(code, 1)}>
                            <FiPlus/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'addLicenses'}>
            <div className={'adFormContainer'}>
                <div className={'adTitleContainer'}>
                    <div className={'iconContainer'}><LuKey /></div>
                    <div className={'title'}>Add Licenses</div>
                </div>
                <Divider/>
                <div className={'adLicenseRows'}>
                    <LicenseModule name={'Drafting Assistance '} code={'DR'}/>
                    <LicenseModule name={'CenterLine Drawing  '} code={'CE'}/>
                    <LicenseModule name={'Municipal Area Calculation '} code={'MU'}/>
                    <LicenseModule name={'Dimensioning Length & Area '} code={'DI'}/>
                    <LicenseModule name={'Quantity '} code={'QU'}/>
                    <LicenseModule name={'Building Section '} code={'SC'}/>
                    <LicenseModule name={'Interior Electrical '} code={'I'}/>
                </div>
                <Divider/>
                <div className={'adDays'}>
                    <span className={'adName'}>Expiry days </span>
                    <Input
                        name={'expiryDays'}
                        value={formData.expiryDays}
                        style={{width:"5rem"}}
                        onChange={handleDaysChange}
                    />
                </div>
            </div>
            <div className={'actionsContainer'}>
                <button className={'cancelButton'} onClick={onClose}>Cancel</button>
                <button className={'confirmButton'} onClick={handleSubmit}>Confirm</button>
            </div>
        </div>
    );
};

export default AddLicenses;