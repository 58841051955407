import React, {useState} from 'react';
import '../AddLicense/AddLicenses.css'
import {LuKey} from "react-icons/lu";
import Divider from "../../../../../stories/Divider/Divider";
import Input from "../../../../../stories/Input/Input";
import {FiMinus, FiPlus} from "react-icons/fi";
import {useCustomer} from "../../../../../app/contextProviders/customerContext";

const RemoveLicense = ({company, onClose}) => {
    const customerContext = useCustomer()
    const availableLicenses = {'DR': 0, 'CE': 0, 'MU': 0, 'DI': 0, 'QU': 0, 'SC': 0, 'I': 0}
    company.licenseSets.forEach(licenseSet => {
        licenseSet.licenses
            .filter(license => license.status === 'available')
            .forEach(availableLicense => {
                const code = availableLicense.code
                availableLicenses[code] = (availableLicenses[code] || 0) + 1
            });
    });

    const initialFormData = {
        companyId: company._id,
        licenseToDelete: {'DR': 0, 'CE': 0, 'MU': 0, 'DI': 0, 'QU': 0, 'SC': 0, 'I': 0},
    }
    const [formData, setFormData] = useState(initialFormData)
    console.log(formData)
    const resetFormData = () => {
        setFormData(initialFormData)
    }
    const handleDaysChange = (e) => {
        setFormData(prevState => ({...prevState, expiryDays: e.target.value}))
    }
    const handleLicenseChange = (code, amount) => {
        setFormData(prevState => ({
                ...prevState,
                licenseToDelete: {
                    ...prevState.licenseToDelete,
                    [code]:prevState.licenseToDelete[code] + amount
                }
            }
        ))
    }
    // console.log(formData)
    const handleSubmit = () => {
        console.log({formData});
        customerContext.removeLicense({...formData})
        onClose()
        resetFormData()
    }

    const LicenseModule = (props) => {
        const {name, code} = props
        return (
            <div className={'adLicenseRow'}>
                <div className={'adName'}>{name}</div>
                <div className={'adDataBlock'}>
                    <div className={'code'}>{code}</div>
                    <div className={'code'}>{availableLicenses[code]}</div>
                    <div className={'adLicenseCountContainer'}>
                        <div className={'reduce'} onClick={() => {
                            if (formData.licenseToDelete[code] > 0) handleLicenseChange(code, -1)
                        }}>
                            <FiMinus/>
                        </div>
                        <div className={'count'}>{formData.licenseToDelete[code]}</div>
                        <div className={'increase'} onClick={() => {
                            if (formData.licenseToDelete[code] < availableLicenses[code]) handleLicenseChange(code, 1)
                        }}>
                            <FiPlus/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'addLicenses'}>
            <div className={'adFormContainer'}>
                <div className={'adTitleContainer'}>
                    <div className={'iconContainer'}><LuKey/></div>
                    <div className={'title'}>Remove Licenses</div>
                </div>
                <Divider/>
                <div className={'adLicenseRows'}>
                    <LicenseModule name={'Drafting Assistance '} code={'DR'}/>
                    <LicenseModule name={'CenterLine Drawing  '} code={'CE'}/>
                    <LicenseModule name={'Municipal Area Calculation '} code={'MU'}/>
                    <LicenseModule name={'Dimensioning Length & Area '} code={'DI'}/>
                    <LicenseModule name={'Quantity '} code={'QU'}/>
                    <LicenseModule name={'Building Section '} code={'SC'}/>
                    <LicenseModule name={'Interior Electrical '} code={'I'}/>
                </div>
            </div>
            <div className={'actionsContainer'}>
                <button className={'cancelButton'} onClick={onClose}>Cancel</button>
                <button className={'confirmButton'} onClick={handleSubmit}>Confirm</button>
            </div>
        </div>
    );
};

export default RemoveLicense;