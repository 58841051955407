import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import './index.css';
import {APP_NAME} from "./config";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import AllProviders from "./app/contextProviders/AllProviders";

const container = document.getElementById('root');
const root = createRoot(container);
document.title = `${APP_NAME}`;
root.render(
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <AllProviders>
                <App/>
            </AllProviders>
        </DndProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
