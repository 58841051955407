import React, {useState} from 'react';
import Text from '../../../../stories/Text/Text'
import './CustomerProfile.css'
import {useCustomer} from "../../../../app/contextProviders/customerContext";
import {useAuth} from "../../../../app/contextProviders/authContext";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

const CustomerProfile = ({company}) => {
    let customerContext
    customerContext = useCustomer()
    let auth
    auth = useAuth()
    const [days, setDays] = useState(company?.days);
    const [editing, setEditing] = useState(false);
    const handleEditClick = () => setEditing(true);
    const handleSaveClick = () => {
        customerContext.setDefaultDays(company._id, days)
        setEditing(false);
    };
    const handleCancelEditing = () => setEditing(false)
    const handleDaysChange = (event) => setDays(event.target.value);
    const toggleStatus = () => {
        if (company.active) {
            customerContext.setCompanyInactive(company._id)
        } else {
            customerContext.setCompanyActive(company._id)
        }
    }
    const toggleDownload = () => {
        if (company.download) {
            customerContext.setDownloadDisabled(company._id)
        } else {
            customerContext.setDownloadEnabled(company._id)
        }
    }
    const icons = {
        'Link': <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_7432_3476)">
                <path d="M8.33338 10.8333C8.69125 11.3118 9.14784 11.7077 9.67217 11.9941C10.1965 12.2806 10.7763 12.4509 11.3723 12.4936C11.9682 12.5363 12.5664 12.4503 13.1262 12.2415C13.686 12.0327 14.1943 11.7059 14.6167 11.2833L17.1167 8.78334C17.8757 7.9975 18.2957 6.94499 18.2862 5.85251C18.2767 4.76002 17.8385 3.71497 17.066 2.94243C16.2934 2.1699 15.2484 1.7317 14.1559 1.7222C13.0634 1.71271 12.0109 2.13269 11.225 2.89168L9.79171 4.31668M11.6667 9.16668C11.3088 8.68824 10.8522 8.29236 10.3279 8.00589C9.80359 7.71943 9.22379 7.54908 8.62784 7.5064C8.03188 7.46372 7.43372 7.54971 6.87392 7.75853C6.31412 7.96735 5.80578 8.29412 5.38338 8.71668L2.88338 11.2167C2.12439 12.0025 1.70441 13.055 1.7139 14.1475C1.7234 15.24 2.1616 16.2851 2.93413 17.0576C3.70667 17.8301 4.75172 18.2683 5.84421 18.2778C6.93669 18.2873 7.9892 17.8673 8.77504 17.1083L10.2 15.6833" stroke="#667085" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_7432_3476">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>,

    }
    const dataStyle = {
        fontFamily: "Inter, serif",
        fontSize: "0.875rem",
        fontWeight: "400",
        lineHeight: "1.25rem",
        color: "#667085",
    }
    const inputStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.5rem',
        padding: '0.625rem 0.875rem',
        alignSelf: 'baseline',
        borderRadius: '0.5rem',
        border: '1px solid #D0D5DD',
        background: '#FFF',
        boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
    }

    return (
        <>
            {
                company &&
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className={'gridRow'}>
                        <div className={'labelColumn'}><Text type={'label'} text={'Company No.'}/></div>
                        <div className={'dataColumn'}><Text type={'data'} text={company?.companyId || ""}/></div>
                    </div>
                    <div className={'gridRow'}>
                        <div className={'labelColumn'}><Text type={'label'} text={'Company Name'}/></div>
                        <div className={'dataColumn'}><Text type={'data'} text={company?.companyProfile.companyName || ""}/></div>
                    </div>
                    <div className={'gridRow'}>
                        <div className={'labelColumn'}><Text type={'label'} text={'Email'}/></div>
                        <div className={'dataColumn'}><Text type={'data'} text={company?.email || ""}/></div>
                    </div>
                    <div className={'gridRow'}>
                        <div className={'labelColumn'}><Text type={'label'} text={'Default Days'}/></div>
                        <div className={'dataColumn'}>
                            <div style={{display:'flex', flexDirection:'row', gap:'1rem'}}>
                                {editing
                                    ?   <>
                                            <input style={{backgroundColor:'#eeeeee', outline:'none', border:'none', width:'3rem'}} value={days} onChange={handleDaysChange} />
                                            <FaCheck color={'green'} onClick={handleSaveClick}/>
                                            <RxCross2 color={'red'} onClick={handleCancelEditing}/>
                                        </>
                                    : <Text type={'data'} text={company?.days || ""}/>
                                }
                                <span>
                                    {!editing && auth.user.role === 'admin' &&
                                        <CiEdit color={'#000'} onClick={handleEditClick} />
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    {auth.user.role === 'admin' &&
                        <div className={'gridRow'}>
                            <div className={'labelColumn'}><Text type={'label'} text={'Status'}/></div>
                            <div
                                className={'dataColumn'} style={{...dataStyle,cursor: auth.user.role === 'admin' ? 'pointer' : 'default'}}
                                onClick={auth.user.role === 'admin' ? toggleStatus : undefined}
                            >
                                {company.active?'ACTIVE':"INACTIVE"}
                            </div>
                        </div>
                    }
                    <div className={'gridRow'}>
                        <div className={'labelColumn'}><Text type={'label'} text={'Download'}/></div>
                        <div
                            className={'dataColumn'} style={{...dataStyle,cursor: auth.user.role === 'admin' ? 'pointer' : 'default'}}
                            onClick={auth.user.role === 'admin' ? toggleDownload : undefined}
                        >
                            {company.download?'Enabled':"Disabled"}
                        </div>
                    </div>
                    {auth.user.role === 'admin' &&
                        <div className={'passwordReset'}>
                            <div className={'passwordResetLink'} onClick={() => customerContext.createOtp(company.userId)}>
                                {"Generate 'Reset Password' link"}
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default CustomerProfile;