import React, {useRef} from 'react';
import {useCustomer} from "../../../../../app/contextProviders/customerContext";
import Divider from "../../../../../stories/Divider/Divider";
import {RiDeleteBinLine} from "react-icons/ri";
import Button from "../../../../../stories/Button/Button";
import InputRef from "../../../../../stories/Input/InputRef";

const MachineDelete = ({company, onClose}) => {
    const customerContext = useCustomer()
    const messageRef = useRef('Hello')
    const machine = customerContext.machineActionOn
    const handleDeleteRelease = () => {
        if (messageRef.current.value) {
            customerContext.deleteCompanyMachine(company._id, machine.machineId, messageRef.current.value)
            onClose()
        }
    }
    const handleDelete = () => {
        if (messageRef.current.value) {
            customerContext.deleteCompanyMachineWithLicenses(company._id, machine.machineId, messageRef.current.value)
            onClose()
        }
    }
    return (
        <div className={'machineActionsModal'}>
            <div className={'modalTitleBlock'}>
                <span className={'modalIcon'}>
                    <RiDeleteBinLine/>
                </span>
                Deleting Machine for {machine.user}
            </div>
            <Divider/>
            <div style={{
                display:'flex',
                flexDirection:'column',
                gap:'1.5rem'
            }}>
                Please provide a reason to delete the machine
                <InputRef
                    ref={messageRef}
                    label={'Message'}
                    placeholder={'Enter a reason to delete'}
                    required
                />
                
                <div style={{
                    display:'flex', flexDirection:'row', justifyContent:'space-between'
                }}>
                    <Button
                        onClick={handleDeleteRelease}
                        label={'Delete the machine and release the licenses'}
                        fill={'#b54708'}
                        color={'#FFFFFF'}
                    />
                    <Button
                        onClick={handleDelete}
                        label={'Delete the machine and licenses'}
                        fill={'#f04438'}
                        color={'#FFFFFF'}
                    />
                </div>
            </div>
        </div>
    );
};

export default MachineDelete;