import React, {useEffect, useState} from 'react';
import './AdminDashboard.css'
import {useDispatch, useSelector} from "react-redux";
import {getAllCompanies, getCustomers, getMachines, selectCompanies, selectCustomers, selectMachines,} from "../../../app/reducers/adminReducer";
import UserList from "./UserList";
import DashboardCard from "./DashboardCard";
import CompanyList from "./CompanyList";
import MachineList from "./MachineList";
import { GiGearHammer } from "react-icons/gi";

const AdminDashboard = () => {
    const dispatch = useDispatch()
    const [selectedCard, setSelectedCard] = useState(null)
    const customers = useSelector(selectCustomers)
    const companies = useSelector(selectCompanies);
    const machines = useSelector(selectMachines);

    useEffect(() => {
        dispatch(getCustomers())
        dispatch(getAllCompanies())
        dispatch(getMachines())
    }, []);

    const userCount = customers.length
    const companyCount = companies.length
    const machineCount = machines.length

    const statCards = [
        {
            cardTitle:"Customers",
            cardValue: userCount,
            cardAction:() => setSelectedCard('Customers'),
        },
        {
            cardTitle:"Companies",
            cardValue: companyCount,
            cardAction:() => setSelectedCard('Companies'),
        },
        {
            cardTitle:"Machines",
            cardValue: machineCount,
            cardAction:() => setSelectedCard('Machines'),
        },
        {
            cardTitle:"Prospective Customers",
            cardValue: <GiGearHammer/>,
            cardAction:() => {},
        },
    ]

    return (
        <div className={'adminDashboard'}>
            <div className={'dashboardCards'}>
                {statCards && statCards.map((card, index)=>{
                    return (
                        <DashboardCard key={index} {...card} />
                    )
                })}
            </div>
            {selectedCard && selectedCard === 'Customers' &&
                <UserList/>
            }
            {selectedCard && selectedCard === 'Companies' &&
                <CompanyList/>
            }
            {selectedCard && selectedCard === 'Machines' &&
                <MachineList/>
            }
        </div>
    );
};

export default AdminDashboard;