function toDate(str){
    // console.log({str})
    if (str) {
        const year = str.substring(0, 4);
        const month = str.substring(4, 6);
        const day = str.substring(6, 8);
        return new Date(year, month - 1, day, 12, 0, 0);
    } else {
        return null;
    }
}

function formatDateToString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
}

function decryptModuleCode(n) {
    // Multiply the number by 4
    const multiplied = n * 4;
    // Drop the last 2 digits
    const droppedDigits = Math.floor(multiplied / 100);
    // Subtract the number by 11
    const result = droppedDigits - 11;
    return result;
}

function getModuleDetails(num, moduleMap){
    const modules = [];
    const moduleCode = decryptModuleCode(num)
    const binaryString = moduleCode.toString(2).padStart(8, "0")
    for (let i = binaryString.length - 1; i >= 0; i--) {
        const character = binaryString[i];
        const index = binaryString.length - 1 - i;
        const powerOfTwo = Math.pow(2, index);
        if (character === "1") {
            const moduleCode = moduleMap[powerOfTwo];
            if (moduleCode) {
                modules.push(moduleCode);
            }
        }
    }
    return {
        // moduleCode, this number should not go to the database so sending the original encoded number
        moduleCode:moduleCode,
        modules: modules.join("-")
    }
}

const getOSDate = (machineId) => {
    // const machineId = '1596367099393866'
    const lengthOfId = machineId.length
    const secondLastChar = machineId.at(lengthOfId-1-1)
    const lastChar = machineId.at(lengthOfId-1)
    let mainString = parseInt(machineId.substring(0, lengthOfId-2))
    if (parseInt(lastChar) === 0){
        mainString = mainString/4
        mainString = mainString.toString().substring(1)
        mainString = `${(new Date().getFullYear().toString().substring(0,2))}${mainString.substring(10,12)}${mainString.at(2)}${mainString.at(9)}${mainString.at(4)}${secondLastChar}`
    }else{
        mainString = mainString/parseInt(lastChar)
        mainString = mainString.toString()
        mainString = mainString.substring(1)
        mainString = `${(new Date().getFullYear().toString().substring(0,2))}${mainString.substring(10,12)}${mainString.at(2)}${mainString.at(9)}${mainString.at(4)}${secondLastChar}`
    }
    return mainString
}

export const readMachineFile = (lines, modules) => {
    const machine = {}
    const moduleMap = {}
    modules.map((module)=> {
        moduleMap[module.value] = module.code
        return null
    })
    lines.forEach((line, lineNumber) => {
        if (lineNumber === 0){
            let data = line.split(' ')
            machine['version'] = data[2].substring(0,7)
            machine['fullVersion'] = data[2]
        }
        if (lineNumber === 1){
            let data = line.split('-')
            machine['machineId'] = data[0]
            machine['osDate'] = getOSDate(data[0])
            const moduleDetails = getModuleDetails(parseInt(data[2]), moduleMap)
            machine['encodedModulesCode'] = parseInt(data[2])
            machine['modulesCode'] = moduleDetails.moduleCode
            machine['modules'] = moduleDetails.modules
        }
        if (lineNumber === 2){
            let data = line.substring(14)
            // console.log(data)
            machine['machineName'] = data
        }
        if (lineNumber === 3){
            let data = line.split(' ')
            // machine['user'] = data[2]
            machine['user'] = line.substring(10)
        }
        if (lineNumber === 4){
            let data = line.split(' ')
            machine['genDate'] = data[2]
        }
        if (lineNumber === 5){
            let data = line.split(' ')
            const date = toDate(data[2])
            date.setDate(date.getDate()+1100)
            machine['expiryDate'] = formatDateToString(date)
        }
    })
    return machine
}

export const readFileIntoArray = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = async () => {
            const fileContent = reader.result; // The file content as a string
            const lines = fileContent.split(/[\r\n]+/); // Split the content by regex for line endings
            resolve(lines);
        };

        reader.onerror = () => {
            console.error('Error reading file:', reader.error);
            resolve(false);
        };

        reader.readAsText(file);
    });
};
