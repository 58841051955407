import React, {useEffect, useState} from 'react';
import ReactTable from "../../../components/ReactTable/ReactTable";
import {useSelector} from "react-redux";
import {selectMachines} from "../../../app/reducers/adminReducer";
import MachineDetails from "./MachineDetails";
import { FaRecycle } from "react-icons/fa";
import {useCustomer} from "../../../app/contextProviders/customerContext";

const MachineList = () => {
    const customerContext = useCustomer()
    const machineList = useSelector(selectMachines)
    const [machines, setMachines] = useState([])
    const [showMachine, setShowMachine] = useState(false)
    const [selectedMachine, setSelectedMachine] = useState(null)
    useEffect(() => {
        setMachines(prevState => machineList)
    }, [machineList]);
    console.log('rendered')
    const handleCloseMachine = () => {
        setSelectedMachine(null)
        setShowMachine(false)
    }
    const handleMachine = (machine) => {
        setSelectedMachine(machine)
        setShowMachine(true)
    }
    const handleRecycle = (machine) => {
        customerContext.recycleMachine(machine)
        handleCloseMachine()
    }

    const columns = [
        {header:'Machine User', accessorKey:'user'},
        {header:'Machine ID', accessorKey:'machineId'},
        {header:'Machine Name', accessorKey:'machineName'},
        {header:'Version', accessorKey:'version'},
        {header:'Modules Installed', accessorKey:'modules'},
        {header:'OS Date', accessorKey:'osDate'},
        {header:'Gen Date', accessorKey:'genDate'},
        {header:'Deleted', accessorKey:'deleted'},
        {header:'Status', accessorKey:'status'},
        {header:'Actions', accessorKey:'Actions',
            cell: ({row}) => (
                <div
                    style={{
                        display: "flex",
                        height: "2rem",
                        padding: "0 1rem",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "0.25rem",
                        alignSelf: "stretch"
                    }}
                >
                    {row.original.deleted && row.original.status !== 'recycled'
                        ?   <span onClick={() => handleMachine(row.original)}>
                                <FaRecycle/>
                            </span>
                        :   null
                    }
                </div>
            )
        },
    ]

    return (
        <>
            {showMachine &&
                <>
                    <div className="overlay active" onClick={handleCloseMachine}></div>
                    <MachineDetails machine={selectedMachine} handleRecycle={handleRecycle} closeBlock={handleCloseMachine}/>
                </>
            }
            {machines &&
                <ReactTable
                    title={'Machines'}
                    Data={machines}
                    columns={columns}
                    onRowClick={()=>{}}
                />
            }
        </>
    );
};

export default MachineList;