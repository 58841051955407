import React, {useState} from 'react';
import './ChangePassword.css'
import Divider from "../../../stories/Divider/Divider";
import Input from "../../../stories/Input/Input";
import Button from '../../../stories/Button/Button'
import {useAuth} from "../../../app/contextProviders/authContext";

const ChangePassword = ({closeModal}) => {
    const auth = useAuth()
    const [formData, setFormData] = useState({
        email: auth.user.email,
        oldPassword:'',
        newPassword:'',
        confirmPassword:'',
    })
    const handleChange = (event) => {
        const {name, value} = event.target
        setFormData(prevState => ({...prevState, [name]:value}))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('Form Submitted',{formData})
        auth.changePassword(formData)
        closeModal()
    }
    const icons = {
        'Lock': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11M5 11H19C20.1046 11 21 11.8954 21 13V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V13C3 11.8954 3.89543 11 5 11Z" stroke="#7F56D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,

    }
    return (
        <div className={'changePassword'}>
            <form onSubmit={handleSubmit}>
                <div className={'cpTopSection'}>
                    <div className={'cpTitleSection'}>
                        <div className={'cpTitleBlock'}>
                            <div className={'cpIconBlock'}>
                                <div className={'cpIcon'}>{icons.Lock}</div>
                            </div>
                            <div className={'cpTitleText'}>Change Password</div>
                        </div>
                    </div>
                    <Divider/>
                    <div className={'cpFormSection'}>
                        <div className={'cpInputSection'}>
                            <div className={'cpInputBlock'}>
                                <div className={'cpInputLabel'}>
                                    Old Password
                                </div>
                                <Input
                                    type={'password'}
                                    placeholder={'Enter your current Password'}
                                    name={'oldPassword'}
                                    value={formData.oldPassword}
                                    onChange={e => handleChange(e)}
                                />
                            </div>
                        </div>
                        <div className={'cpInputSection'}>
                            <div className={'cpInputBlock'}>
                                <div className={'cpInputLabel'}>
                                    New Password
                                </div>
                                <Input
                                    type={'password'}
                                    placeholder={'Enter a new Password'}
                                    name={'newPassword'}
                                    value={formData.newPassword}
                                    onChange={e => handleChange(e)}
                                />
                            </div>
                        </div>
                        <div className={'cpInputSection'}>
                            <div className={'cpInputBlock'}>
                                <div className={'cpInputLabel'}>
                                    Re-enter New Password
                                </div>
                                <Input
                                    type={'password'}
                                    placeholder={'Re-enter the same new Password'}
                                    name={'confirmPassword'}
                                    value={formData.confirmPassword}
                                    onChange={e => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'cpFooterSection'}>
                    <Divider/>
                    <div className={'cpFooterContent'}>
                        <div className={'cpFooterActions'}>
                            <div className={'cpFooterActionButton'}>
                                <Button label={'Cancel'} onClick={closeModal}/>
                            </div>
                            {formData.newPassword === formData.confirmPassword &&
                                <div className={'cpFooterActionButton'}>
                                    <Button
                                        type={'submit'}
                                        label={'Submit'}
                                        fill={'#7F56D9'}
                                        color={'#FFFFFF'}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;