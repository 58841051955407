import React from 'react';
import {useCustomer} from "../../../../../app/contextProviders/customerContext";
import {useSelector} from "react-redux";
import ReactTable from "../../../../../components/ReactTable/ReactTable";
import Button from "../../../../../stories/Button/Button";
import UpdateExpiryDate from "./UpdateExpiryDate";

const ManageLicense = () => {
    const customerContext = useCustomer()
    const companies = useSelector(state => state.customers.companies)
    const company = companies[0]
    const licenseSets = company?.licenseSets
    const machines = company?.machines
    const allLicenses = licenseSets?.reduce((licenses, licenseSet) => [...licenses, ...licenseSet.licenses], []);
    const licenses = []
    if (allLicenses) {
        for (let license of allLicenses) {
            const o = {...license}
            if (license.machine) {
                const machine = machines.find(machine => machine._id === license.machine)
                o.user = machine.user
                o.version = machine.version
            } else {
                o.user = null
            }
            licenses.push(o)
        }
    }
    console.log({licenses})
    const columns = [
        {header:"Machine User", accessorKey:"user"},
        {header:"Code", accessorKey:"code"},
        {header:"Expiry Date", accessorKey:"expiryDate",
            cell: ({row}) => (
                new Date(row.original.expiryDate)
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, '')
            )
        },
        {header:"Version", accessorKey:"version"},
        {header:"Status", accessorKey:"status"},
        // {header:"", accessorKey:""},
        // {header:"", accessorKey:""},
    ]
    const onRowSelection = (rows) => {
        const licenses = []
        rows.map(row => licenses.push(row.original._id))
        return <UpdateExpiryDate companyId={company._id} licenses={licenses} />
    }
    return (
        <div>
            {
                licenses && licenses.length &&
                <ReactTable
                    title={'Manage Licenses'}
                    Data={licenses}
                    columns={columns}
                    onRowClick={()=>{}}
                    options={{
                        enableRowSelection: true,
                        onRowSelection
                    }}
                />
            }
        </div>
    );
};

export default ManageLicense;