import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../../app/contextProviders/authContext";
import {toast} from "react-toastify";
import RegisterUser from "./RegisterUser";

const RegisterAccount = () => {
    const {otp, companyId} = useParams()
    const auth = useAuth()
    const navigate = useNavigate()
    if (auth.isAuthenticated()){
        navigate('/', {replace:true})
    }
    const handleSubmit = async ({username, email, password})=> {
        const error = await auth.registerUser({username, email, password, otp, companyId})
        console.log(error)
        if (error) toast.error(error)
    }
    return (
        <RegisterUser submitHandler={handleSubmit}/>
    );
};

export default RegisterAccount;
