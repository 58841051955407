import React from 'react';
import {useNavigate} from "react-router-dom";
import Button from './stories/Button/Button'

const UnauthorizedPage = () => {
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate('/')
    }
    return (
        <div style={{color:'black'}}>
            <h2>403 - Unauthorized Access</h2>
            <p>You do not have permission to access this page.</p>
            <Button label={'Go Back'} onClick={handleGoBack}/>
            {/* You can customize this content further based on your needs */}
        </div>
    );
};

export default UnauthorizedPage;
