import React from 'react';
import './Text.css'

const Text = (props) => {
    const {type, text} = props
    const classes = {
        label: "labelText",
        message: "messageText",
        error: "errorMessageText",
        data: "dataText",
        moduleTitle: "smallColumnTitleText",
        cardTitle: "cardTitleText",
        pageTitle: "pageTitleText",
        default: "defaultText"
    }

    return (
        <div className={`${(classes[type]!==undefined)?classes[type]:classes['default']}`}>
        {/*<div>*/}
            {text}
        </div>
    );
};

export default Text;
