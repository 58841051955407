import React, {useState} from 'react';
import { FaArrowLeftLong } from "react-icons/fa6";
import Button from "../../../../../stories/Button/Button";
import './EditProfile.css'
import Divider from '../../../../../stories/Divider/Divider'
import Input from "../../../../../stories/Input/Input";
import {useDispatch, useSelector} from "react-redux";
import {useCustomer} from "../../../../../app/contextProviders/customerContext";
import {useNavigate} from "react-router-dom";

const EditProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const customerContext = useCustomer()
    const company = useSelector((state) => state.customers.currentCompany);
    const handleNavigateBack = () => navigate(-1)
    // Initialize form state with the company profile data
    const [formData, setFormData] = useState({
        companyId: company?.companyProfile.companyId,
        companyName: company?.companyProfile.companyName || '',
        email: company?.companyProfile.email || '',
        ccEmails: company?.companyProfile.ccEmails || [],
        ccEmail1: (company?.companyProfile.ccEmails && company?.companyProfile.ccEmails[0]) || '',
        ccEmail2: (company?.companyProfile.ccEmails && company?.companyProfile.ccEmails[1]) || '',
        address: company?.companyProfile.address || '',
        phone: company?.companyProfile.phone || '',
        gstNo: company?.companyProfile.gstNo || '',
        panNo: company?.companyProfile.panNo || '',
        thumbnail: company?.companyProfile.thumbnail || '',
    });

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Create an array of ccEmails
        const ccEmailsArray = [formData.ccEmail1, formData.ccEmail2].filter(Boolean);
        // Update the formData with the array of ccEmails
        setFormData((prevData) => ({...prevData, ccEmails: ccEmailsArray,}));
        // Dispatch an action to update the company profile in the Redux store or make an API call
        customerContext.updateCompanyProfile({...formData, ccEmails: ccEmailsArray,})
        // You may also handle redirection or other actions after the profile is updated
        handleNavigateBack()
    };

    const avatarStyle = {
        background: `var(--Avatar-user-square-Olivia-Rhye, url(${formData.thumbnail}), lightgray 50% / cover no-repeat)`,
    };

    return (
        <>
        {company &&
            <div className={'editProfile'}>
                <div className={'epHeaderSection'}>
                    <div className={'epPageHeader'}>
                        <div className={'epPageHeaderContent'}>
                            <div className={'epPageHeaderContentText'}>{'Company Settings'}</div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.75rem"}}>
                                <Button label={'Back'} icon={<FaArrowLeftLong/>} onClick={handleNavigateBack}/>
                            </div>
                        </div>
                        <Divider/>
                    </div>
                </div>
                <div className={'epContentSection'}>
                    <div className={'epContentContainer'}>
                        <div className={'epContentPage'}>
                            <div className={'epContentPageTitleSection'}>
                                <div className={'epFeatureIconContainer'}>
                                    <div className={'epFeatureIcon'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21M4 7H20C21.1046 7 22 7.89543 22 9V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V9C2 7.89543 2.89543 7 4 7Z" stroke="#7F56D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className={'epContentPageTitleContainer'}>
                                    Company Profile
                                </div>
                            </div>
                            <Divider/>
                            <div className={'epContent'}>
                                <div className={'epContentForm'}>
                                    <div className={'epContentRow'}>
                                        <div className={'epContentRowLabel'}>Company Name</div>
                                        <Input name={'companyName'} value={formData.companyName} disabled/>
                                    </div>
                                    <Divider />
                                    <div className={'epContentRow'}>
                                        <div className={'epContentRowLabel'}>Company Logo</div>
                                        <div className={'epImageBlock'}>
                                            <div className={'epAvatar'} style={avatarStyle}/>
                                            <div className={'epAvatarActionBlock'}>
                                                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#667085', fontWeight: 500, fontSize: '0.875rem'}}>
                                                        Delete
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#6941C6', fontWeight: 500, fontSize: '0.875rem'}}>
                                                        Update
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className={'epContentRow'}>
                                        <div className={'epContentRowLabel'}>Email</div>
                                        <Input name={'email'} value={formData.email} onChange={handleChange} />
                                    </div>
                                    <Divider />
                                    <div className={'epContentRow'}>
                                        <div className={'epContentRowLabel'}>CC Emails</div>
                                        <div className={'epCCEmailsContainer'}>
                                            <Input name={'ccEmail1'} type={'email'} value={formData.ccEmail1} onChange={handleChange}/>
                                            <Input name={'ccEmail2'} type={'email'} value={formData.ccEmail2} onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className={'epContentRow'}>
                                        <div className={'epContentRowLabel'}>Phone</div>
                                        <Input name={'phone'} value={formData.phone} onChange={handleChange}/>
                                    </div>
                                    <Divider />
                                    <div className={'epContentRow'}>
                                        <div className={'epContentRowLabel'}>GST Number</div>
                                        <Input name={'gstNo'} value={formData.gstNo} onChange={handleChange}/>
                                    </div>
                                    <Divider />
                                    <div className={'epContentRow'}>
                                        <div className={'epContentRowLabel'}>PAN Number</div>
                                        <Input name={'panNo'} value={formData.panNo} onChange={handleChange}/>
                                    </div>
                                    <Divider />
                                    <div className={'epFormFooterSection'}>
                                        <div className={'epFormFooter'}>
                                            <div className={'epFormFooterActionsBlock'}>
                                                <div style={{display: "flex", alignItems: "center", gap: "0.75rem"}}>
                                                    <Button label={'Cancel'} onClick={handleNavigateBack} />
                                                </div>
                                                <div style={{display: "flex", alignItems: "center", gap: "0.75rem"}}>
                                                    <Button label={'Save Changes'} fill={'#7F56D9'} color={'#FFFFFF'} onClick={handleSubmit}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
};

export default EditProfile;