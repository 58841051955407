import React from 'react';
import {useLicense} from "../../../../app/contextProviders/licenseContext";
import {useCustomer} from "../../../../app/contextProviders/customerContext";
import LicenseGrid from "../../../../stories/LicenseGrid/LicenseGrid";

const LicenseDataBlock = ({company}) => {
    const systemModules = useLicense().modules
    const customerContext = useCustomer()
    const generateLicenseStatusData = (licenseSets) => {
        // Initialize an object to store the license status data
        const licenseStatusData = {};

        // Get the current date
        const currentDate = new Date();

        // Loop through each license set
        licenseSets.forEach((licenseSet) => {
            // Loop through each license in the license set
            licenseSet.licenses.forEach((license) => {
                const { code, status, expiryDate } = license;

                // Initialize or update the license status data for the code
                if (!licenseStatusData[code]) {
                    licenseStatusData[code] = {code, purchased: 0, used: 0, available: 0, expired: 0,};
                }

                // Update the license status data based on the license's status and expiry date
                if (new Date(expiryDate) <= new Date(currentDate)) {
                    // License has expired
                    licenseStatusData[code].purchased++;
                    licenseStatusData[code].expired++;
                } else {
                    // License has not expired
                    switch (status) {
                        case 'available':
                            licenseStatusData[code].purchased++;
                            licenseStatusData[code].available++;
                            break;
                        case 'in use':
                            licenseStatusData[code].purchased++;
                            licenseStatusData[code].used++;
                            break;
                        default:
                            break;
                    }
                }
            });
        });

        // Convert the license status data object into an array of objects
        return licenseStatusData
    }
    let licenseModules = {}
    let renderData = []
    if (company){
        licenseModules = generateLicenseStatusData(company?.licenseSets||{})
        renderData = []
        systemModules.map(module => {
            const record = {}
            record.name = module.name;
            record.code = module.code;
            record.purchased = licenseModules[module.code] ? licenseModules[module.code].purchased : 0;
            record.used = licenseModules[module.code] ? licenseModules[module.code].used : 0;
            record.available = licenseModules[module.code] ? licenseModules[module.code].available : 0;
            record.expired = licenseModules[module.code] ? licenseModules[module.code].expired : 0;
            renderData.push(record);
        })
        // console.log({renderData})
    }
    return (
        <>
            {company &&
                <LicenseGrid data={renderData} />
            }
        </>
    )
};

export default LicenseDataBlock;