import React, {useEffect} from 'react';
import {useAuth} from "../app/contextProviders/authContext";
import {useLicense} from "../app/contextProviders/licenseContext";
import AdminDashboard from "./admin/dashboard/AdminDashboard";
import NewCompanyDashboard from "./company/dashboard/newDashboard";
import ProspectiveCustomers from "./admin/prospectiveCustomer/ProspectiveCustomers";

const Dashboard = () => {
    const auth = useAuth();
    const licenseContext = useLicense();
    useEffect(() => {
        licenseContext.loadModules()
    }, [auth.isAuthenticated()]);
    return (
        <>
            {auth.user && auth.user.role === 'company' && <><NewCompanyDashboard/></>}
            {auth.user && auth.user.role === 'admin' && <AdminDashboard/>}
            {auth.user && auth.user.role === 'sales' && <><ProspectiveCustomers/></>}
        </>
    );
};

export default Dashboard;
