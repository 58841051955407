import React, {useEffect, useState} from 'react';
import ProspectiveCustomerTable from "./ProspectiveCustomerTable";
import {useDispatch, useSelector} from "react-redux";
import {listProspectiveCustomers} from "../../../app/reducers/prospectiveCustomerReducer";
import {selectProspectiveCustomersList} from "../../../app/reducers/prospectiveCustomerReducer";
import AddProspectiveCustomer from "./AddProspectiveCustomer";
import ProspectiveCustomerDetails from "./ProspectiveCustomerDetails";
import Button from '../../../stories/Button/Button'

const ProspectiveCustomers = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listProspectiveCustomers())
    }, [dispatch])
    const list = useSelector(selectProspectiveCustomersList)
    const [showAddBlock, setShowAddBlock] = useState(false)
    const [showDetailsBlock, setShowDetailsBlock] = useState(false)
    const toggleAddBlock = () => {
        setShowAddBlock(!showAddBlock)
    }

    const icons = {
        Plus: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.99984 4.16675V15.8334M4.1665 10.0001H15.8332" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        Minus: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M4.16675 10H15.8334" stroke="#6941C6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
    }
    const AddProspectiveCustomerButton = <Button
        icon={showAddBlock? icons.Minus: icons.Plus}
        label={showAddBlock ? 'Hide Form' : 'Add a Prospective Customer'}
        onClick={toggleAddBlock}
        fill={showAddBlock ? '#FFFFFF' : '#7F56D9'}
        color={showAddBlock ? '#7F56D9' : '#FFFFFF'}
    />

    return (
        <div>
            {showAddBlock && <AddProspectiveCustomer closeThisBlock={toggleAddBlock}/>}
            {showDetailsBlock && <ProspectiveCustomerDetails closeThisBlock={setShowDetailsBlock}/>}
            {list && list.length > 0 &&
                <ProspectiveCustomerTable
                    prospectiveCustomers={list}
                    features={{
                        setShowDetailsBlock,
                        extraComponent: AddProspectiveCustomerButton
                    }}
                />
            }
        </div>
    );
};

export default ProspectiveCustomers;
