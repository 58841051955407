import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../axios";

export const listProspectiveCustomers = createAsyncThunk(
    'prospectiveCustomer/list',
    async () => {
        const response = await axios.get('/prospectiveCustomer/list');
        return response.data
    }
);

export const submitProspectiveCustomer = createAsyncThunk(
    'prospectiveCustomer/add',
    async (data) => {
        const response = await axios.post('prospectiveCustomer/add', data)
        return response.data
    }
)

export const updateProspectiveCustomer = createAsyncThunk(
    'prospectiveCustomer/update',
    async (data, {dispatch}) => {
        const response = await axios.put(`prospectiveCustomer/update/${data._id}`, data)
        await dispatch(listProspectiveCustomers());
        return response.data
    }
)

export const submitNote = createAsyncThunk(
    'prospectiveCustomer/addNote',
    async (data) => {
        console.log({data})
        const response = await axios.post('prospectiveCustomer/addNote', data,
            {'Content-Type': 'multipart/form-data'}
        )
        console.log(response.data)
        return response.data
    }
)

export const promoteCustomer = createAsyncThunk(
    'prospectiveCustomer/promote',
    async (data, {dispatch}) => {
        console.log({data})
        const response = await axios.post('company/createCompany', data)
        console.log(response.data)
        dispatch(listProspectiveCustomers())
        return response.data
    }
)

export const createOTP = createAsyncThunk(
    'prospectiveCustomer/createOTP',
    async (id, {dispatch}) => {
        const response = await axios.get(`prospectiveCustomer/createOtp/${id}`)
        console.log(response.data)
        dispatch(listProspectiveCustomers())
        return response.data
    }
)

export const deleteProspectiveCustomer = createAsyncThunk(
    'prospectiveCustomer/delete',
    async (id, {dispatch}) => {
        const response = await axios.delete(`prospectiveCustomer/delete/${id}`)
        console.log(response.data)
        dispatch(listProspectiveCustomers())
        return response.data
    }
)

export const prospectiveCustomerSlice = createSlice({
        name: 'prospectiveCustomer',
        initialState: {
            list: [],
            currentCustomer: null,
            company: null,
        },
        reducers: {
            setCurrentCustomer: (state, action) => {
                const customerId = action.payload;
                const selectedCustomer = state.list.find(customer => customer._id === customerId);
                state.currentCustomer = selectedCustomer || null;
            },
            resetCurrentCustomer: (state) => {
                state.currentCustomer = null
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(listProspectiveCustomers.fulfilled, (state, action) => {
                    state.list = action.payload
                })
                .addCase(submitProspectiveCustomer.fulfilled, (state, action) => {
                    state.list = action.payload
                })
                .addCase(updateProspectiveCustomer.fulfilled, (state, action) => {
                    state.currentCustomer = action.payload
                })
                .addCase(submitNote.fulfilled, (state, action) => {
                    state.currentCustomer = action.payload
                })
                .addCase(promoteCustomer.fulfilled, (state, action) => {
                    state.company = action.payload
                })
        }
    }
)

export const selectProspectiveCustomersList = (state) => state.prospectiveCustomers.list;
export const selectCurrentCustomer = (state) => state.prospectiveCustomers.currentCustomer;

export const { setCurrentCustomer, resetCurrentCustomer } = prospectiveCustomerSlice.actions;

export default prospectiveCustomerSlice.reducer
