import React from 'react';
import './MachineTable.css'
import MachineTableHeader from "./MachineTableHeader";
import MachineTableRows from "./MachineTableRows";
import Divider from "../../../../stories/Divider/Divider";
import ReactTable from "../../../../components/ReactTable/ReactTable";
import {FaWrench} from "react-icons/fa";
import {RiDeleteBinLine} from "react-icons/ri";
import {useCustomer} from "../../../../app/contextProviders/customerContext";
import {useAuth} from "../../../../app/contextProviders/authContext";

const MachineTable = (props) => {
    const customerContext = useCustomer()
    const auth = useAuth()
    const {company, action, deleteAction} = props
    const machines = company.machines
    const handleReBuild = (machine) => {
        customerContext.setMachineActionOn(machine)
        action()
    }
    const handleDelete = (machine) => {
        customerContext.setMachineActionOn(machine)
        deleteAction()
    }
    const allLicenses = company.licenseSets?.reduce((licenses, licenseSet) => [...licenses, ...licenseSet.licenses], []);
    
    const columns = [
        {header: 'User', accessorKey: 'user'},
        {header: 'MachineId', accessorKey: 'machineId'},
        {header: 'Machine Name', accessorKey: 'machineName'},
        {
            header: 'Version', accessorKey: 'fullVersion',
            cell: ({row}) => (row.original.fullVersion ? row.original.fullVersion : row.original.version)
        },
        {header:'Modules', accessorKey:'modules'},
        {header:'Expiry Date',
            cell: ({row}) => {
                    const licenses = allLicenses.filter(license => license.machine === row.original._id)
                    if (licenses.length){
                        const minExpiryDate = licenses.reduce((minDate, license) => {
                            // Compare expiry dates
                            return license.expiryDate < minDate ? license.expiryDate : minDate;
                        }, licenses[0].expiryDate)
                        return new Date(minExpiryDate).toISOString().slice(0, 10).replace(/-/g, '')
                    }else{
                        return <div></div>
                    }
                }
            },
        {
            header:'Actions',
            accessorKey:'actions',
            cell: ({ row }) => (
                <div
                    style={{
                        display: "flex",
                        height: "2rem",
                        padding: "0 1rem",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "0.25rem",
                        alignSelf: "stretch"
                    }}
                >
                    <span onClick={()=>handleReBuild(row.original)}><FaWrench/></span>
                    {
                        auth.user && auth.user.role === 'admin' &&
                        <span onClick={() => handleDelete(row.original)}><RiDeleteBinLine/></span>
                    }
                </div>
            ),
        }
    ]
    
    return (
        <>
            {machines &&
                <ReactTable
                    title={'Machines'}
                    Data={machines}
                    columns={columns}
                    onRowClick={() => {
                    }}
                />
            }
        </>
    )
    // <div style={{padding:"1rem"}}>
    //     <MachineTableHeader company={company} />
    //     <Divider/>
    //     <MachineTableRows company={company} action={action}/>
    // </div>
    // );
};

export default MachineTable;