import {createContext, useContext, useState} from "react";
import axios from "../axios";
import {useDispatch, useSelector} from "react-redux";
import {
    activateCompany, addMachine, clearUploadError, createLicenseSet, deactivateCompany,
    decrementLicense, deleteLicense, deleteMachine, deleteMachineWithLicenses, disableDownload, editCompanyProfile,
    enableDownload, incrementLicense, reformatMachine, refreshCustomer, resetLicenseExpiryDate,
    setCustomer, switchMachine, updateDays, updateLicenseExpiryDate
} from "../reducers/customerReducer";
import {toast} from "react-toastify";
import {copyTextToClipboard} from "../../utils";
import {getMachines} from "../reducers/adminReducer";

const CustomerContext = createContext(null)

export const CustomerProvider = ({children}) => {
    const [machineActionOn, setMachineActionOn] = useState(null)
    const dispatch = useDispatch()
    const currentCustomer = useSelector(state => state.customers.customer);
    const companies = useSelector(state => state.customers.companies);

    const refreshCompanies = (data) => dispatch(refreshCustomer(data))

    const newUserCompany = (data) => dispatch(setCustomer(data))
    const addMachineToCompany = (companyId, machine) => dispatch(addMachine({companyId, machine}))
    const deleteCompanyMachine = (companyId, machineId, comment) => dispatch(deleteMachine({companyId, machineId, comment}))
    const deleteCompanyMachineWithLicenses = (companyId, machineId, comment) => dispatch(deleteMachineWithLicenses({companyId, machineId, comment}))
    const reformatCompanyMachine = (companyId, oldMachineId, newMachine, comment) => dispatch(reformatMachine({companyId, oldMachineId, newMachine, comment}))
    const switchCompanyMachine = (companyId, oldMachineId, newMachine, comment) => dispatch(switchMachine({companyId, oldMachineId, newMachine, comment}))

    const incrementCompanyLicense = (companyId, moduleCode) => dispatch(incrementLicense({companyId, moduleCode}))
    const decrementCompanyLicense = (companyId, moduleCode) => dispatch(decrementLicense({companyId, moduleCode}))

    const submitLicenseSet = (formData) => dispatch(createLicenseSet({formData}))
    const removeLicense = (formData) => dispatch(deleteLicense(formData))

    const setDefaultDays = (companyId, days) => dispatch(updateDays({companyId, days}))

    const setCompanyActive =  (companyId) => dispatch(activateCompany(companyId))
    const setCompanyInactive = (companyId) => dispatch(deactivateCompany(companyId))

    const setDownloadEnabled = (companyId) => dispatch(enableDownload(companyId))
    const setDownloadDisabled = (companyId) => dispatch(disableDownload(companyId))
    const clearTheUploadError = () => dispatch(clearUploadError())
    const updateCompanyProfile = (formData) => dispatch(editCompanyProfile(formData))
    const createOtp = async (userId) => {
        const response = await axios.get(`/auth/resetPassword/${userId}`)
        if (response){
            const user = response.data
            const baseUrl = window.location.origin
            const link = `${baseUrl}/reset/${user.otp}/${userId}`
            copyTextToClipboard(link)
            // navigator.clipboard.writeText(link)
                .then(() => {
                    toast.success(`URL copied to clipboard ${link}`)
                    console.log('Copy this link\n', link)
                })
                .catch((error) => {
                    toast.error('Error copying to clipboard')
                    alert(`There was an error copying the URL. Please manually copy the URL until a permanent fix is provided ${link}`)
                    console.error('Error copying to clipboard:', error);
                });
        }else{
            toast.error('There was an error')
        }
    }
    const recycleMachine = async (machine) => {
        const response = await axios.put(`/machine/recycle/${machine._id}`)
        if (response.data){
            console.log(response.data)
            dispatch(getMachines())
        }else{
            toast.error('There was a problem recycling the machine')
        }
    }
    const updateExpiryDate = async (companyId, newExpiryDate, requestedLicenses) => dispatch(updateLicenseExpiryDate({companyId, newExpiryDate, requestedLicenses}))
    const resetExpiryDate = async (companyId, requestedLicenses) => dispatch(resetLicenseExpiryDate({companyId, requestedLicenses}))

    return (
        <CustomerContext.Provider value = {{currentCustomer, companies, machineActionOn, setMachineActionOn,
            incrementCompanyLicense, decrementCompanyLicense, submitLicenseSet, removeLicense,
            refreshCompanies, setCompanyActive, setCompanyInactive,
            setDownloadEnabled, setDownloadDisabled,
            newUserCompany,
            setDefaultDays, addMachineToCompany, deleteCompanyMachine, clearTheUploadError, deleteCompanyMachineWithLicenses,
            reformatCompanyMachine, switchCompanyMachine,
            updateCompanyProfile, createOtp, recycleMachine,
            updateExpiryDate, resetExpiryDate
        }}>
            {children}
        </CustomerContext.Provider>
    )
}

export const useCustomer = () => {
    return useContext(CustomerContext)
}
