import React, {useState} from 'react';
import './MachineActions.css'
import Divider from "../../../../../stories/Divider/Divider";
import FeatherIcon from "feather-icons-react";
import {useCustomer} from "../../../../../app/contextProviders/customerContext";
import FileDropZone from "../../../../../components/dropzone/FileDropZone";
import {readFileIntoArray, readMachineFile} from "../Machine";
import {useLicense} from "../../../../../app/contextProviders/licenseContext";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../../../app/contextProviders/authContext";
import {toast} from "react-toastify";

const MachineActions = ({company, onClose}) => {
    const customerContext = useCustomer()
    const licenseContext = useLicense()
    const navigate = useNavigate()
    const [selectedAction, setSelectedAction] = useState('switch');
    const [selectedReason, setSelectedReason] = useState('');
    const machine = customerContext.machineActionOn
    const handleActionChange = (event) => {
        setSelectedAction(event.target.value);
        setSelectedReason('');
    };
    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };
    console.log({machine})
    const reasons = {
        switch: {
            project: "Project requirement",
            internal: "Company internal requirement",
            hardware: "For Machine issues"
        },
        reformat: {
            hardware: "For Machine issues",
            software: "Some AutoCAD issues"
        }
    }

    const onFileDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const lines = await readFileIntoArray(file);
            const isValidFile = lines[0].substring(0, 3) === 'VER'
            if (isValidFile){
                const newMachine = readMachineFile(lines, licenseContext.modules)
                if (newMachine.machineId !== machine.machineId){
                    if (selectedAction === 'reformat') {
                        customerContext.reformatCompanyMachine(company._id, machine.machineId, newMachine, reasons[selectedAction][selectedReason])
                    }else{
                        customerContext.switchCompanyMachine(company._id, machine.machineId, newMachine, reasons[selectedAction][selectedReason])
                    }
                }else{
                    toast.error("old and new machine Id are same")
                }
                onClose()
                // navigate('/')
            }
            if (!isValidFile) {
                console.log("Error uploading file")
            }
        }
    }

    return (
        <div className={'machineActionsModal'}>
            <div className={'modalTitleBlock'}>
                <span className={'modalIcon'} >
                    <FeatherIcon icon={'tool'}/>
                </span>
                Changing Machine for {machine.user}
            </div>
            <Divider/>

            <div className={'actionBlock'}>
                <span className={'blockTitle'}>Choose an Action</span>
                <div className={'actionOptions'}>
                    <label><input type="radio" value="switch" checked={selectedAction === 'switch'} onChange={handleActionChange}/>Switch</label>
                    <label><input type="radio" value="reformat" checked={selectedAction === 'reformat'} onChange={handleActionChange}/>Reformat</label>
                </div>
            </div>

            <div className={'actionBlock'}>
                <span className={'blockTitle'}>Choose a Reason</span>
                <div className={'reasonOptions'}>
                    {Object.keys(reasons[selectedAction]).map(key => (
                            <label><input type="radio" key={key} value={key} checked={selectedReason === key} onChange={handleReasonChange}/>{reasons[selectedAction][key]}</label>

                    ))}
                </div>
            </div>

            <div className={'fileUploadBlock'}>
                <span className={'blockTitle'}>Upload your new machine file</span>
                <div className={'fileUpload'}>
                    <FileDropZone onDrop={onFileDrop} acceptedFileTypes={['.txt']}/>
                </div>
            </div>

        </div>
    );
};

export default MachineActions;
