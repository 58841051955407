import React, {useState} from 'react';
import './CreateCompanyForm.css'
import Divider from '../../../stories/Divider/Divider'
import Input from '../../../stories/Input/Input'
import Button from '../../../stories/Button/Button'
import {FiMinus, FiPlus} from "react-icons/fi";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {promoteCustomer} from "../../../app/reducers/prospectiveCustomerReducer";

const CreateCompanyForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const state = useLocation().state
    const [formData, setFormData] = useState({
        userId: state?._id||null,
        companyName: state?.companyName || '',
        email: state?.emailAddress || '',
        ccEmails: [],
        address: '',
        phone: state?.contactNumber || '',
        gstNo: '',
        panNo: '',
        thumbnail: '',
        days: 30,
        status: 'active',
        download: true,
    });

    const handleChange = (event) => {
        const {name, value} = event.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCCEmailChange = (index, value) => {
        let updatedCCEmails = [...formData.ccEmails];
        updatedCCEmails[index] = value;
        setFormData(prevState => ({...prevState, ccEmails:updatedCCEmails}));
    };

    const toggleDownload = () => {
        handleChange('download', !formData.download)
    }
    const handleSubmit = () => {
        setFormData(prevState => ({...prevState, ccEmails: prevState.ccEmails.filter(Boolean)}))
        dispatch(promoteCustomer(formData))
        navigate(-1)
    }

    const icons = {
        'TitleIcon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M16 21.5V5.5C16 4.96957 15.7893 4.46086 15.4142 4.08579C15.0391 3.71071 14.5304 3.5 14 3.5H10C9.46957 3.5 8.96086 3.71071 8.58579 4.08579C8.21071 4.46086 8 4.96957 8 5.5V21.5M4 7.5H20C21.1046 7.5 22 8.39543 22 9.5V19.5C22 20.6046 21.1046 21.5 20 21.5H4C2.89543 21.5 2 20.6046 2 19.5V9.5C2 8.39543 2.89543 7.5 4 7.5Z" stroke="#7F56D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        'PlusIcon': <FiPlus/>,
        'MinusIcon': <FiMinus/>
    }

    return (
        <div className={'createCompanyForm'}>
            <div className={'ccfHeaderSection'}>
                <div className={'ccfHeaderBlock'}>
                    <div className={'ccfIconBlock'}>
                        <div className={'ccfIcon'}>
                            {icons.TitleIcon}
                        </div>
                    </div>
                    <div className={'ccfTitleBlock'}>
                        <div className={'ccfTitle'}>
                            Create Company for Prospective Customer
                        </div>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className={'ccfContentSection'}>
                <div className={'ccfContent'}>
                    <div className={'ccfFormBlock'}>
                        <div className={'ccfForm'}>
                            <div className={'ccfFormRow'}>
                                <div className={'ccfInputSection'}>
                                    <div className={'ccfInputBlock'}>
                                        <div className={'ccfInputLabel'}>Company Name</div>
                                        <Input value={formData.companyName} name={'companyName'} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className={'ccfCombineInputs'}>
                                    <div className={'ccfInputSection'}>
                                        <div className={'ccfInputBlock'}>
                                            <div className={'ccfInputLabel'}>GST No.</div>
                                            <Input value={formData.gstNo} name={'gstNo'} onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className={'ccfInputSection'}>
                                        <div className={'ccfInputBlock'}>
                                            <div className={'ccfInputLabel'}>PAN No.</div>
                                            <Input value={formData.panNo} name={'panNo'} onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'ccfFormRow'}>
                                <div className={'ccfInputSection'}>
                                    <div className={'ccfInputBlock'}>
                                        <div className={'ccfInputLabel'}>
                                            Contact No.
                                        </div>
                                        <Input value={formData.phone} name={'phone'} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className={'ccfInputSection'}>
                                    <div className={'ccfInputBlock'}>
                                        <div className={'ccfInputLabel'}>Primary Email</div>
                                        <Input
                                            placeholder={'Enter the primary Email'}
                                            type={'email'}
                                            value={formData.email}
                                            name={'email'}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className={'ccfInputSection'}>
                                    <div className={'ccfInputBlock'}>
                                        <div className={'ccfInputLabel'}>Email CC 1</div>
                                        <Input
                                            placeholder={'Enter Email'}
                                            type={'email'}
                                            value={formData.ccEmails[0]||''}
                                            name={'ccEmail1'}
                                            onChange={(e) => handleCCEmailChange(0, e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={'ccfInputSection'}>
                                    <div className={'ccfInputBlock'}>
                                        <div className={'ccfInputLabel'}>Email CC 2</div>
                                        <Input
                                            placeholder={'Enter Email'}
                                            type={'email'}
                                            value={formData.ccEmails[1]||''}
                                            name={'ccEmail2'}
                                            onChange={(e) => handleCCEmailChange(1, e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div className={'ccfFormRow'}>
                                <div className={'ccfInputSectionRow'}>
                                    <div className={'ccfInputBlockRow'}>
                                        <div className={'ccfInputBlockRowInput'}>
                                            <div className={'ccfInputLabel'}>Download Licenses</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'ccfInputSectionRow'}>
                                    <div className={'ccfInputBlockRow'}>
                                        <div className={'ccfInputBlockRowInput'}>
                                            <div
                                                className={`ccfInputValueText ${formData.download?' ccfEnabledText':' ccfDisabledText'}`}
                                                onClick={toggleDownload}
                                            >
                                                {formData.download? "Enabled":"Disabled"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'ccfInputSectionRow'}>
                                    <div className={'ccfInputBlockRow'}>
                                        <div className={'ccfInputBlockRowInput'}>
                                            <div className={'ccfInputLabel'}>
                                                Default License Days
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'ccfInputSectionRow'}>
                                    <div className={'ccfInputBlockRow'}>
                                        <div className={'ccfInputBlockRowInput'}>
                                            <Input value={formData.days} onChange={(e) => handleChange('days',e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'ccfFooter'}>
                        <Divider/>
                        <div className={'ccfFooterContent'}>
                            <div className={'ccfFooterActionsBlock'}>
                                <div className={'ccfButtonWrapper'}>
                                    <Button
                                        label={'Discard'}
                                        onClick={() => navigate(-1)}
                                    />
                                </div>
                                <div className={'ccfButtonWrapper'}>
                                    <Button
                                        label={'Create Company'}
                                        onClick={handleSubmit}
                                        fill={'#7F56D9'}
                                        color={'#FFFFFF'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCompanyForm;