import React from 'react';
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate('/')
    }
    return (
        <div style={{color:'black'}}>
            <h2>404 - Page Not Found</h2>
            <p>We are sorry the page you are looking for does not exist</p>
            <button onClick={handleGoBack}>Go Back</button>
            {/* You can customize this content further based on your needs */}
        </div>
    );
};

export default PageNotFound;