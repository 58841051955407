import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectCustomers} from "../../../app/reducers/adminReducer";
import {setCustomer} from "../../../app/reducers/customerReducer";
import ReactTable from "../../../components/ReactTable/ReactTable";
import { FaUserEdit } from "react-icons/fa";
import UpdateUser from "./UpdateUser";
import {useAuth} from "../../../app/contextProviders/authContext";

const UserList = () => {
    const auth = useAuth()
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const customers = useSelector(selectCustomers)
    const [updateUser, setUpdateUser] = useState(false)
    const [userRecord, setUserRecord] = useState({})
    const handleUpdateUser = (e, row) => {
        e.stopPropagation()
        setUserRecord(row)
        setUpdateUser(true)
    }
    const handleUpdatedUser = async (user) => {
        // console.log({user})
        await auth.setNewCompanyUser(user)
    }
    const closeUpdateUser = () => {
        setUpdateUser(false)
        setUserRecord({})
    }
    const handleSelectUser = async (rowData) => {
        // console.log(rowData)
        await dispatch(setCustomer(rowData))
        Navigate('/company/dashboard')
    }
    const columns = [
        {header:'User Name', accessorKey:'username'},
        {header:'Email', accessorKey:'email'},
        {header:'Status', accessorKey:'active'},
        {header:'Actions', accessorKey:'Actions',
            cell: ({row}) => (
                <div
                    style={{
                        display: "flex",
                        height: "2rem",
                        padding: "0 1rem",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "0.25rem",
                        alignSelf: "stretch"
                    }}
                >
                    <span
                        onClick={(e) =>handleUpdateUser(e, row.original)}
                    >
                        <FaUserEdit/>
                    </span>
                </div>
            )
        },
    ]
    return (
        <>
            {
                customers &&
                    <ReactTable
                        title={'Customers'}
                        Data={customers}
                        columns={columns}
                        onRowClick={handleSelectUser}
                    />
            }
            {
                updateUser &&
                    <>
                        <div className="overlay active" onClick={closeUpdateUser}></div>
                        <UpdateUser userRecord={userRecord} onSubmit={handleUpdatedUser} close={closeUpdateUser}/>
                    </>
            }
        </>
    )
}

export default UserList;
