import { configureStore } from '@reduxjs/toolkit';
import authReducer from "./reducers/authReducer";
import adminReducer from "./reducers/adminReducer";
import prospectiveCustomerReducer from "./reducers/prospectiveCustomerReducer";
import customerReducer from "./reducers/customerReducer";
import licenseCodeReducer from "./reducers/licenseCodeReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    admin: adminReducer,
    prospectiveCustomers: prospectiveCustomerReducer,
    customers: customerReducer,
    licenseCode: licenseCodeReducer,
  },
});
