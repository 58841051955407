import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../axios";
import {toast} from "react-toastify";

export const getCustomerCompanies = createAsyncThunk(
    'customer/getCustomerCompanies',
    async (userId) => {
        const response = await axios.get(`/company/getUserCompanies/${userId}`);
        // console.log('getCustomerCompanies()', response.data)
        return response.data
    }
);

export const setCustomer = createAsyncThunk(
    'customer/setCustomer',
    async (data) => {
        const response = await axios.get(`/company/getUserCompanies/${data._id}`);
        // console.log('getCustomerCompanies()', response.data)
        return {customer: data, companies: response.data}
    }
);

export const refreshCustomer = createAsyncThunk(
    'customer/refreshCustomer',
    async (data) => {
        console.log({data})
        const response = await axios.get(`/company/getUserCompanies/${data._id}`);
        console.log('getCustomerCompanies()', response.data)
        return {companies: response.data}
    }
);

export const addMachine = createAsyncThunk(
    'customer/addMachine',
    async (data, {dispatch, rejectWithValue}) => {
        const {companyId, machine} = data;
        try {
            const response = await axios.post('/machine/processMachine', {companyId, machine});
            dispatch(getCustomerCompanies(response.data.userId));
            return response.data;
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
);

export const deleteMachine = createAsyncThunk(
    'customer/deleteMachine',
    async (data, {dispatch, rejectWithValue}) => {
        // const {companyId, machineId, comment} = data;
        try {
            const response = await axios.post('/machine/deleteMachine', data);
            dispatch(getCustomerCompanies(response.data.userId));
            toast.success('The machine was deleted')
            return response.data;
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            toast.error(error.error || 'An error occurred')
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
);

export const deleteMachineWithLicenses = createAsyncThunk(
    'customer/deleteMachineWithLicenses',
    async (data, {dispatch, rejectWithValue}) => {
        // const {companyId, machineId, comment} = data;
        try {
            const response = await axios.post('/machine/deleteMachineWithLicenses', data);
            dispatch(getCustomerCompanies(response.data.userId));
            toast.success('The machine was deleted with attached licenses')
            return response.data;
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            toast.error(error.error || 'An error occurred')
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
);

export const reformatMachine = createAsyncThunk(
    'customer/reformatMachine',
    async (data, {dispatch, rejectWithValue}) => {
        console.log({data})
        const {companyId, oldMachineId, newMachine, comment} = data;
        console.log({comment})
        try {
            const response = await axios.post('/machine/reformatMachine', {companyId, oldMachineId, newMachine, comment});
            dispatch(getCustomerCompanies(response.data.userId));
            toast.info('Machine updated successfully');
            return response.data;
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            toast.error(error.error||'An error occurred');
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
);

export const switchMachine = createAsyncThunk(
    'customer/switchMachine',
    async (data, {dispatch, rejectWithValue}) => {
        console.log({data})
        const {companyId, oldMachineId, newMachine, comment} = data;
        console.log({comment})
        try {
            const response = await axios.post('/machine/switchMachine', {companyId, oldMachineId, newMachine, comment});
            dispatch(getCustomerCompanies(response.data.userId));
            toast.success('Machine switch successfully');
            return response.data;
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            toast.error(error.error || 'An error occurred');
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
);

export const updateDays = createAsyncThunk(
    'customer/updateDays',
    async (data, {dispatch}) => {
        const {companyId, days} = data
        const response = await axios.post(`/company/updateDays/${companyId}/${days}`);
        dispatch(getCustomerCompanies(response.data.userId))
        return response.data
    }
);

export const activateCompany = createAsyncThunk(
    'customer/activateCompany',
    async (companyId, {dispatch}) => {
        const response = await axios.post(`/company/activate/${companyId}`);
        dispatch(getCustomerCompanies(response.data.userId))
        return response.data
    }
);

export const deactivateCompany = createAsyncThunk(
    'customer/deactivateCompany',
    async (companyId, {dispatch}) => {
        const response = await axios.post(`/company/deactivate/${companyId}`);
        dispatch(getCustomerCompanies(response.data.userId))
        return response.data
    }
);

export const enableDownload = createAsyncThunk(
    'customer/enableDownload',
    async (companyId, {dispatch}) => {
        const response = await axios.post(`/company/enableDownload/${companyId}`);
        dispatch(getCustomerCompanies(response.data.userId))
        return response.data
    }
);

export const disableDownload = createAsyncThunk(
    'customer/disableDownload',
    async (companyId, {dispatch}) => {
        const response = await axios.post(`/company/disableDownload/${companyId}`);
        dispatch(getCustomerCompanies(response.data.userId))
        return response.data
    }
);

export const incrementLicense = createAsyncThunk(
    'customer/incrementLicense',
    async (data, {dispatch}) => {
        const {companyId, moduleCode} = data
        const response = await axios.post(`/company/licenseModuleIncrement/${companyId}/${moduleCode}`);
        dispatch(getCustomerCompanies(response.data.userId))
        return response.data
    }
);

export const decrementLicense = createAsyncThunk(
    'customer/decrementLicense',
    async (data, {dispatch}) => {
        const {companyId, moduleCode} = data
        const response = await axios.post(`/company/licenseModuleDecrement/${companyId}/${moduleCode}`);
        dispatch(getCustomerCompanies(response.data.userId))
        return response.data
    }
);

export const createLicenseSet = createAsyncThunk(
    'license/createLicenseSet',
    async (data, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/license/createLicenseSet', data)
            // console.log("response.data._id: ", response.data._id)
            dispatch(getCustomerCompanies(response.data.userId))
            return response.data
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
)

export const deleteLicense = createAsyncThunk(
    'license/createLicenseSet',
    async (data, {dispatch, rejectWithValue}) => {
        try {
            console.log({data})
            const response = await axios.post('/license/newRemoveLicense', data)
            // console.log("response.data._id: ", response.data._id)
            dispatch(getCustomerCompanies(response.data.userId))
            return response.data
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
)

export const editCompanyProfile = createAsyncThunk(
    'customer/editCompanyProfile',
    async (data, {dispatch, rejectWithValue}) => {
        try {
            console.log({data})
            const response = await axios.put('/company/profile/update', data)
            console.log("response.data._id: ", response.data._id)
            dispatch(getCustomerCompanies(response.data.userId))
            return response.data
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
)

export const updateLicenseExpiryDate = createAsyncThunk(
    'customer/updateLicenseExpiryDate',
    async (data, {dispatch, rejectWithValue}) => {
        try {
            console.log({data})
            const response = await axios.post('/company/updateExpiryDate', data)
            // console.log("response.data._id: ", response.data._id)
            dispatch(getCustomerCompanies(response.data.userId))
            return response.data
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
)

export const resetLicenseExpiryDate = createAsyncThunk(
    'customer/resetLicenseExpiryDate',
    async (data, {dispatch, rejectWithValue}) => {
        try {
            console.log({data})
            const response = await axios.post('/company/resetExpiryDate', data)
            // console.log("response.data._id: ", response.data._id)
            dispatch(getCustomerCompanies(response.data.userId))
            return response.data
        } catch (error) {
            // Use rejectWithValue to include the error message in the payload
            console.log({error})
            return rejectWithValue(error.error || 'An error occurred');
        }
    }
)


const updateCompanyInArray = (companiesArray, updatedCompany) => {
    return companiesArray.map((company) => {
        if (company._id === updatedCompany._id) {
            return updatedCompany;
        }
        return company;
    });
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        customer: null,
        companies: [],
        currentCompany: null,
        machineUploadError: null,
        fileUploadError: null,
    },
    reducers: {
        clearUploadError: (state) => {
            return {...state, machineUploadError: null,};
        },
        setCurrentCompany: (state, action) => {
            return { ...state, currentCompany:action.payload}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetLicenseExpiryDate.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(updateLicenseExpiryDate.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(editCompanyProfile.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(setCustomer.fulfilled, (state, action) => {
                state.customer = action.payload.customer
                state.companies = action.payload.companies
            })
            .addCase(refreshCustomer.fulfilled, (state, action) => {
                state.companies = action.payload.companies
            })
            .addCase(getCustomerCompanies.fulfilled, (state, action) => {
                // console.log('customerReducer: getCustomerCompanies(): ', {action})
                state.companies = action.payload;
            })
            .addCase(updateDays.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(activateCompany.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(deactivateCompany.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(enableDownload.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(disableDownload.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(incrementLicense.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(decrementLicense.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(createLicenseSet.fulfilled, (state, action) => {
                console.log("Payload when fulfilled", action.payload)
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(addMachine.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(addMachine.rejected, (state, action) => {
                console.log("Error encountered:", action.payload)
                state.machineUploadError = action.payload
            })
            .addCase(addMachine.pending, (state, action) => {
                state.machineUploadError = null
            })
            .addCase(deleteMachine.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(deleteMachine.rejected, (state, action) => {
                console.log("Error encountered:", action.payload)
                state.fileUploadError = action.payload
            })
            .addCase(deleteMachine.pending, (state, action) => {
                state.fileUploadError = null
            })
            .addCase(reformatMachine.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(reformatMachine.rejected, (state, action) => {
                console.log("Error encountered:", action.payload)
                state.fileUploadError = action.payload
            })
            .addCase(reformatMachine.pending, (state, action) => {
                state.fileUploadError = null
            })
            .addCase(switchMachine.fulfilled, (state, action) => {
                state.companies = updateCompanyInArray(state.companies, action.payload);
            })
            .addCase(switchMachine.rejected, (state, action) => {
                console.log("Error encountered:", action.payload)
                state.fileUploadError = action.payload
            })
            .addCase(switchMachine.pending, (state, action) => {
                state.fileUploadError = null
            })
            .addCase(incrementLicense.rejected, (state, action) => {
                state.error = action.payload; // Assuming you have an 'error' field in your state
            })
    },
})

export default customerSlice.reducer
export const {
    clearUploadError,
    setCurrentCompany
} = customerSlice.actions;